import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import MainLayout from '../MainLayout'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import ListHistoryComponent from '../../components/ListHistory'
import ListAccountComponent from '../../components/ListAccount'
import moment from 'moment'
import FormDialog from '../../components/FormDialog'
import ButtonComponent from '../../components/Button'
import types from '../../constants/types'
import Loading from '../../components/Loading'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import cn from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import Tour from 'reactour'
import { getIsGuideByKey, GUIDE_TYPE_ENUM, setIsGuideByKey } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCountries,
  selectCountriesOnly,
  selectGuideListByKey,
} from 'stores/config/config.selector'
import { useTranslation } from 'react-i18next'
import { MousePointer } from 'react-feather'
import DialogPolicyPointContainer from '../DialogPolicyPoint'
import TransferAcc from '../../components/TransferAcc'
import ConfirmModal from '../../components/ConfirmModal'
import { accountApi } from '../../api/accountApi'
import { showError, showSuccess } from '../../stores/alert/slice.alert'
import RenameTagModal from '../../components/RenameTagModal'
import { IconButton, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  left: {
    width: 'calc(50% - 30px)',
    marginRight: 15,
  },
  right: {
    width: '50%',
  },
  wrapInfo: {
    display: 'flex',
  },
  textField: {
    margin: '12px 5px',
    width: 'calc(100% - 10px)',
    float: 'left',
  },
  textFieldFirst: {
    margin: '14px 5px',
    width: 'calc(50% - 10px)',
  },
  form: {
    display: 'inline-block',
  },
  box: {
    background: 'white',
    padding: '15px',
    margin: '15px 0px',
    boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)!important',
    '& h4': {
      marginTop: 0,
      color: '#02A351',
    },
  },
  wrapAvatar: {
    textAlign: 'center',
  },
  avatar: {
    margin: '0px auto',
    width: 120,
    height: 120,
  },
  giftPoint: {
    marginTop: '12px',
  },
  viewPolicyPoint: {
    color: 'rgb(255, 128, 0)',
    cursor: 'pointer',
    textDecoration: 'underline overline',
    '&:hover': {
      color: '#618833',
    },
  },
  wrapPolicyPoint: {
    textAlign: 'center',
    padding: '0px 15px',
    marginBottom: '20px',
    '& h4': {
      marginBottom: 0,
      padding: 0,
      color: '#05AE34',
    },
    '& h6': {
      marginTop: 5,
      fontSize: 14,
      color: 'red',
    },
  },
  time: {
    margin: '12px 0px 12px 0px',
    '& span': {
      fontWeight: 'bold',
    },
  },
  buttonChange: {
    marginLeft: 5,
  },
  service: {
    display: 'flex',
    width: '100%',
    padding: 0,
    '& li': {
      padding: 15,
      listStyle: 'none',
      width: '33.333%',
      border: '1px solid #f0f0f0',
      '& span': {
        fontWeight: 'bold',
        color: '#05AE34',
      },
    },
  },
  history: {
    width: '100%',
    '& h4': {
      marginBottom: 5,
      color: '#05AE34',
    },
  },
  accounts: {
    width: '100%',
    '& h4': {
      marginBottom: 5,
      color: '#05AE34',
    },
  },
  titleChangePassword: {
    textAlign: 'center',
  },
  formChangePassword: {
    padding: '0px 10px 10px 10px',
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
    '& .MuiTextField-root': {
      margin: '0px 5px',
    },
    '& .MuiFormControl-root': {
      display: 'inline-block',
      width: 'calc(100% - 30px)',
      margin: 5,
    },
  },
  changePasswordButton: {
    textAlign: 'center',
    marginBottom: 30,
  },
  wrapTextareaCopy: {
    textAlign: 'center',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changePasswordSucc: {
    color: '#05AE34',
  },
  changePasswordErr: {
    color: 'red',
  },
  changePasswordStatus: {
    marginTop: 0,
  },
  textarea: {
    width: '70%',
    minHeight: 60,
    marginRight: 15,
    padding: 5,
    borderColor: '#ddd',
    resize: 'none',
  },
  copySuccess: {
    textAlign: 'center',
    color: '#05AE34',
  },
  errorInput: {
    '& .MuiFilledInput-root': {
      border: '1px solid red',
    },
  },
  error: {
    color: 'red',
    position: 'absolute',
    bottom: '-15px',
    fontSize: '14px',
    left: '5px',
  },
  wrapAutocomplete: {
    display: 'inline-block',
    width: 'calc(100% - 10px)',
    margin: '12px 5px',
  },
  col6: {
    width: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  col12: {
    width: '526px',
    display: 'inline-block',
    position: 'relative',
  },
  wrapButtonChange: {
    textAlign: 'right',
    marginTop: 15,
  },
  success: {
    textAlign: 'center',
    color: '#05AE34',
  },
  error: {
    color: 'red',
    marginLeft: '6px',
  },
  copyButton: {
    padding: 4,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}))
const SettingAccountView = (props) => {
  const {
    user,
    onWhatYouNeed,
    share,
    onSubmitChangePassword,
    changePassword,
    onChangePasswordStatus,
    whatYouNeed,
    onGetInviteCode,
    inviteCode,
    onUnlink,
    unlink,
    loading,
    updateUser,
    messageUnlink,
    messageInviteError,
    onGetUser,
  } = props
  const classes = useStyles()
  const history = useHistory()
  const [passwordOld, setPasswordOld] = useState('')
  const [passwordNew, setPasswordNew] = useState('')
  const [passwordNew2, setPasswordNew2] = useState('')
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [isChangePasswordSucss, setIsChangePasswordSucss] = useState(null)
  const [isViewPolicyPoint, setIsViewPolicyPoint] = useState(false)
  const [isSnackbar, setIsSnackbar] = useState(false)
  const [copySuccess, setCopySuccess] = useState('')
  const textAreaRef = useRef(null)
  const [inviteCodeData, setInviteCodeData] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [isInviteCodePopup, setIsInviteCodePopup] = useState(false)
  const [isSubmitChangePassword, setIsSubmitChangePassword] = useState(false)
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [countryError, setCountryError] = useState('')
  // const [proviceError, setProviceError] = useState("");
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [getOpenPoits, setGetOpenPoits] = useState(0)

  const [business, setBusiness] = useState([])
  const [province, setProvince] = useState([])
  const [updateInfoError, setUpdateInfoError] = useState('')
  const [updateInfoSuccess, setUpdateInfoSuccess] = useState('')
  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.account_manager)
  )
  const [tourConfig, setTourConfig] = useState(null)
  const { t } = useTranslation()
  const countries = useSelector(selectCountries)
  const countriesOnly = useSelector(selectCountriesOnly)

  const [country, setCountry] = useState()

  const [isOpenTransfer, setIsOpenTransfer] = useState(false)

  const [isShowConfirmTransfer, setIsShowConfirmTransfer] = useState(false)

  const [transferedId, setTransferedId] = useState('')

  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback((id) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.account_manager) &&
      tourOverview?.length
    ) {
      setTourConfig(tourOverview)
      setIsGuideByKey(GUIDE_TYPE_ENUM.account_manager)
    }
  }, [tourOverview])

  useEffect(() => {
    setName(user && user.name)
    setEmail(user && user.email)
    if (user && user.phone) {
      setPhone(user && user.phone)
    }
    setCompany(user.company)
    setCountry(user?.country)
    setProvince(user?.province)

    if (whatYouNeed?.business && user?.business) {
      let business = _.toString(user.business).split(',')
      const data = business?.map((b) => {
        return whatYouNeed.business.find((bus) => bus.id === b)
      })
      // console.log(data);
      setBusiness(data)
    }

    // console.log('user ===>', user)
  }, [whatYouNeed, user])
  useEffect(() => {
    if (unlink) {
      const params = {
        fields: 'user,users,package,history,version,versions,business,province',
      }
      onWhatYouNeed(params)
    }
  }, [unlink])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search)
    if (searchParam && searchParam.get('openPoits')) {
      setGetOpenPoits(searchParam.get('openPoits'))
    }

    const params = {
      fields: 'user,users,package,history,version,versions,business,province',
    }
    onWhatYouNeed(params)
  }, [])

  useEffect(() => {
    setPasswordOld('')
    setPasswordNew('')
    setPasswordNew2('')
    setIsChangePasswordSucss(changePassword)
  }, [changePassword])

  useEffect(() => {
    if (inviteCode) {
      setInviteCodeData(
        `${window.location.origin}/invite-code?ref=invite-code&code=${
          inviteCode && inviteCode.code
        }`
      )
    }
  }, [inviteCode])

  const handleChangePassword = (key, value) => {
    switch (key) {
      case 'passwordOld':
        setPasswordOld(value)
        break
      case 'passwordNew':
        setPasswordNew(value)
        break
      case 'passwordNew2':
        setPasswordNew2(value)
        break
      default:
        break
    }
  }

  const handleSubmitChangePassword = () => {
    setIsSubmitChangePassword(true)
    if (!_.isEmpty(passwordNew) && !_.isEmpty(passwordNew2)) {
      if (!user.noPwSet && _.isEmpty(passwordOld)) {
        setErrorPassword(t('noti.reset_password_invalid'))
      } else {
        if (passwordNew !== passwordNew2) {
          setErrorPassword(t('noti.new_password_invalid'))
        } else {
          setErrorPassword('')
          onSubmitChangePassword({ passwordOld, passwordNew })
          // setPasswordOld("");
          // setPasswordNew("");
          // setPasswordNew2("");
        }
      }
    } else {
      setErrorPassword(t('noti.reset_password_invalid'))
    }
  }

  const handleOpenIsViewPolicyPoint = () => {
    setIsViewPolicyPoint(true)
  }

  const handleCloseIsViewPolicyPoint = () => {
    setGetOpenPoits(0)
    setIsViewPolicyPoint(false)
    const params = {
      fields: 'user,users,package,history,version,versions,business,province',
    }
    onWhatYouNeed(params)
  }

  const handleOpenChangePassword = () => {
    setIsSubmitChangePassword(false)
    setErrorPassword(null)
    setIsChangePassword(true)
  }

  const handleCloseChangePassword = () => {
    setIsChangePassword(false)
    setPasswordOld('')
    setPasswordNew('')
    setPasswordNew2('')
    onChangePasswordStatus(null)
  }

  const handleCloseInviteCodePopup = () => {
    setIsInviteCodePopup(false)
  }

  const handleSubmitInviteCode = () => {
    onGetInviteCode()
    setIsInviteCodePopup(true)
  }
  const copyToClipboard = (e) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setCopySuccess(t('noti.invite_account'))
  }
  const handleClosePoup = () => {
    setIsChangePassword(false)
  }
  const handleUnlink = async (item) => {
    await onUnlink(item)
    await onGetUser(user.id)
  }
  const handleChange = (key, value) => {
    switch (key) {
      case 'name':
        setName(value)
        break
      case 'phone':
        setPhone(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'company':
        setCompany(value)
        break
      case 'province':
        setProvince(value)
        break
      case 'business':
        setBusiness(value)
        break
      case 'country':
        setCountry(value)
        setProvince('')
        break

      default:
        break
    }
  }
  const handleSubmitChangeInfo = () => {
    if (validate()) {
      const data = {
        name,
        phone,
        email,
        company,
        business: business ? business?.map((v) => v.id) : [],
        country,
        province,
      }
      updateUser(data, user.id).then((res) => {
        if (res && res.id) {
          setUpdateInfoError('')
          setUpdateInfoSuccess(t('noti.update_info'))
        } else {
          setUpdateInfoSuccess('')
          setUpdateInfoError(t('noti.update_info_fail'))
        }
      })
    }
  }
  const providerOptions = useMemo(() => {
    if (country) {
      const countrySelected = countries.find((c) => c.countryName === country)
      if (countrySelected) return countrySelected?.provinces
    }
  }, [country])

  const validate = () => {
    setNameError('')
    setPhoneError('')
    setEmailError('')
    let isValid = true
    if (!name || name.length <= 2) {
      isValid = false
      setNameError(t('noti.name_incorrect'))
    }
    if (!email) {
      isValid = false
      setEmailError(t('noti.email'))
    }

    if (email) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
      if (!pattern.test(email)) {
        isValid = false
        setEmailError(t('noti.email_incorrect'))
      }
    }

    if (!phone) {
      isValid = false
      setPhoneError(t('noti.phone'))
    }

    if (phone) {
      if (!phone?.trim() || phone.length < 5 || phone.length > 20) {
        isValid = false
        setPhoneError(t('noti.phone_incorrect'))
      }
    }
    if (!country) {
      isValid = false
      setCountryError(t('update.country_incorrect'))
    } else {
      setCountryError('')
    }
    // if (country && !province) {
    //   isValid = false;
    //   setProviceError(t("update.province_incorrect"));
    // } else {
    //   setCountryError("");
    // }

    return isValid
  }

  let user_business = []
  if (business) {
    user_business = business.filter((b) => b != null)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const closeTour = () => setTourConfig(null)

  const handleTransferAccount = async () => {
    try {
      if (!transferedId.trim()) {
        dispatch(showError('Please enter transfer account id!'))
        return
      }

      const payload = {
        mainUserId: user.id,
        linkedUserId: transferedId,
      }
      const res = await accountApi.transferToAccount(payload)

      dispatch(
        showSuccess(
          `Đã hoàn thành chuyển đổi tài khoản chính từ ${user.id} sang tài khoản mới ${res.id}, bạn vui lòng sử dụng tài khoản ${res.id} để tiếp tục sử dụng BomBot`
        )
      )

      setIsShowConfirmTransfer(false)
      setIsOpenTransfer(false)

      setTimeout(() => {
        window.location.reload()
      }, 2000)

      await onGetUser(user.id)
    } catch (error) {
      dispatch(showError(error.message))
    }
  }

  return (
    <MainLayout>
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        accentColor="#05ae34"
        onRequestClose={closeTour}
      />
      {loading && <Loading />}
      <div className={classes.wrapInfo}>
        <div className={classes.left}>
          <div className={classes.box} id="info_account">
            <h4>{t('account_management.account_information.title')}</h4>
            <FormControl className={classes.form}>
              <div>
                <TextField
                  error={false}
                  disabled
                  size="small"
                  label={t('account_management.account_information.account_id')}
                  variant="outlined"
                  value={user?.id || ''}
                  className={classes.textFieldFirst}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={copied ? t('copied') : t('copy')}>
                        <IconButton
                          className={classes.copyButton}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleCopy(user?.id)
                            setCopied(true)
                            setTimeout(() => setCopied(false), 2000) // Reset "copied" after 2 seconds
                          }}
                          size="small"
                          style={{
                            marginLeft: '12px',
                          }}
                        >
                          <img
                            src="/image/Clipboard-alt.svg"
                            alt="Copy"
                            style={{ width: '20px', height: '20px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className={classes.col6}>
                <TextField
                  error={false}
                  disabled
                  label={t('account_management.account_information.name')}
                  value={name || ''}
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  onChange={(event) => handleChange('name', event.target.value)}
                />
                {!_.isEmpty(nameError) && (
                  <p className={classes.error}>{nameError}</p>
                )}
              </div>
              <div className={classes.col6}>
                <TextField
                  error={false}
                  disabled
                  label={t('account_management.account_information.phone')}
                  value={phone || ''}
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  onChange={(event) =>
                    handleChange('phone', event.target.value)
                  }
                />
                {!_.isEmpty(phoneError) && (
                  <p className={classes.error}>{phoneError}</p>
                )}
              </div>
              <div className={classes.col6}>
                <TextField
                  error={false}
                  // disabled
                  label={
                    t('account_management.account_information.email') + ' (*)'
                  }
                  value={email || ''}
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  onChange={(event) =>
                    handleChange('email', event.target.value)
                  }
                />
                {!_.isEmpty(emailError) && (
                  <p className={classes.error}>{emailError}</p>
                )}
              </div>
              <div className={classes.col6}>
                <TextField
                  error={false}
                  // disabled
                  label={t('account_management.account_information.company')}
                  value={company || ''}
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  onChange={(event) =>
                    handleChange('company', event.target.value)
                  }
                />
              </div>
              {/* <TextField
                error={false}
                // disabled
                label="Ngành nghề"
                defaultValue={user.business}
                variant="filled"
                className={classes.textField}
              /> */}
              <div className={classes.wrapAutocomplete}>
                <Autocomplete
                  id="business"
                  multiple
                  options={(whatYouNeed && whatYouNeed.business) || []}
                  disableCloseOnSelect
                  size={'small'}
                  getOptionLabel={(option) => option?.name || ''}
                  style={{ marginTop: 0, height: 'auto' }}
                  onChange={(e, value) => handleChange('business', value)}
                  value={user_business || []}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      variant="outlined"
                      {...params}
                      label={t(
                        'account_management.account_information.industry'
                      )}
                    />
                  )}
                  // renderTags={(value) => <p>value</p>}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.name}
                    </>
                  )}
                />
              </div>

              {/* <TextField
                error={false}
                disabled
                label="Tỉnh/thành phố"
                defaultValue={user.province}
                variant="filled"
                className={classes.textField}
              /> */}
              <div className={classes.wrapAutocomplete}>
                <Autocomplete
                  className="w-100"
                  id="country"
                  value={country || ''}
                  options={countriesOnly || []}
                  style={{ marginTop: 10 }}
                  onChange={(e, value) => handleChange('country', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('form_collect_info.country') + ' (*)'}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
              {!_.isEmpty(countryError) && (
                <p className={classes.error}>{countryError}</p>
              )}
              {country && country !== 'Other' && (
                <div className={classes.wrapAutocomplete}>
                  <Autocomplete
                    id="province"
                    className={classes.autocomplete}
                    options={providerOptions || []}
                    getOptionLabel={(option) => option || ''}
                    style={{ marginTop: 10 }}
                    onChange={(e, value) => handleChange('province', value)}
                    value={province || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          'account_management.account_information.province'
                        )}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )}
            </FormControl>
            {updateInfoSuccess && (
              <p className={classes.success}>{updateInfoSuccess}</p>
            )}
            {updateInfoError && (
              <p className={classes.error}>{updateInfoError}</p>
            )}
            <div className={classes.wrapButtonChange}>
              <ButtonComponent
                className={'btn--rounder'}
                title={t('common.btn.update_info')}
                backgroundColor="#05AE34"
                color="white"
                handleClickButton={() => handleSubmitChangeInfo()}
              />
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.box}>
            <h4>{t('account_management.service_information.title')}</h4>
            <div className={classes.wrapAvatar}>
              {user.avatar ? (
                <Avatar
                  className={classes.avatar}
                  alt={user.name}
                  src={user.avatar}
                />
              ) : (
                <Avatar className={classes.avatar} alt={user.name} />
              )}
              <h3>{user.name}</h3>
              <Button
                style={{
                  background: '#FF8000',
                  fontSize: 14,
                  color: 'white',
                }}
                id="change_password"
                className="btn--rounder"
                variant="contained"
                onClick={() => handleOpenChangePassword()}
              >
                {t('common.btn.reset_password')}
              </Button>
              <p className={classes.giftPoint}>
                {t('account_management.service_information.giftPoint')}:{' '}
                <strong>{whatYouNeed?.package?.giftPoint}</strong>
              </p>
              <a
                className={classes.viewPolicyPoint}
                onClick={() => handleOpenIsViewPolicyPoint()}
              >
                {t('account_management.service_information.privacyPoint')}
              </a>
              <p className={classes.time}>
                {t('account_management.service_information.expire_date')}:{' '}
                <span>
                  {moment(
                    whatYouNeed &&
                      whatYouNeed.package &&
                      whatYouNeed.package.expiredAt
                  ).format('DD/MM/YYYY HH:mm')}
                </span>
              </p>
              <ul className={classes.service}>
                <li>
                  <div>
                    <p>
                      {t('account_management.service_information.total_mess')}
                    </p>
                    <span>
                      {whatYouNeed &&
                      whatYouNeed.package &&
                      whatYouNeed.package.totalMes > 1000000000
                        ? t('account_management.service_information.unlimited')
                        : whatYouNeed &&
                          whatYouNeed.package &&
                          whatYouNeed.package.totalMes}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <p>
                      {t('account_management.service_information.sent_mess')}
                    </p>
                    <span>
                      {whatYouNeed &&
                        whatYouNeed.package &&
                        whatYouNeed.package.totalSent}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <p>
                      {t('account_management.service_information.remain_mess')}
                    </p>
                    <span>
                      {whatYouNeed &&
                      whatYouNeed.package &&
                      whatYouNeed.package.type &&
                      whatYouNeed.package.totalMes > 1000000000
                        ? t('account_management.service_information.unlimited')
                        : whatYouNeed &&
                          whatYouNeed.package &&
                          whatYouNeed.package.totalMes -
                            whatYouNeed.package.totalSent}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.accounts}>
        <h4>{t('account_management.linked_account.title')}</h4>
        <ListAccountComponent
          handleInviteCode={() => handleSubmitInviteCode()}
          data={whatYouNeed}
          handleUnlink={(item) => handleUnlink(item)}
          messageUnlink={messageUnlink}
          messageInviteError={messageInviteError}
          statusUnlink={unlink}
          user={user}
          handleOpenTransferConfirm={() => setIsShowConfirmTransfer(true)}
          setTransferedId={setTransferedId}
          handleOpenTransfer={() => setIsOpenTransfer(true)}
        />
      </div>
      <div className={classes.history} id="order_history">
        <h4>{t('account_management.payment_history.title')}</h4>
        <ListHistoryComponent data={whatYouNeed} />
      </div>
      <FormDialog
        isOpen={isChangePassword}
        handleClose={() => handleCloseChangePassword()}
      >
        <div className={cn('p-3', classes.wrapChangePassword)}>
          <h4 className={cn(classes.titleChangePassword, 'mb-3')}>
            {t('common.btn.reset_password')}
          </h4>
          {!isChangePasswordSucss && (
            <FormControl className="w-100">
              {!user.noPwSet && (
                <TextField
                  error={false}
                  label={t('update.old_pass')}
                  variant="outlined"
                  size="small"
                  autoFocus={false}
                  className={cn(
                    isSubmitChangePassword &&
                      _.isEmpty(passwordOld) &&
                      classes.errorInput,
                    'w-100 mb-3'
                  )}
                  onChange={(event) =>
                    handleChangePassword('passwordOld', event.target.value)
                  }
                  name="password"
                  inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                />
              )}
              <TextField
                error={false}
                label={t('update.new_pass')}
                // defaultValue={passwordNew}
                variant="outlined"
                size="small"
                autoFocus={false}
                className={cn(
                  isSubmitChangePassword &&
                    _.isEmpty(passwordNew) &&
                    classes.errorInput,
                  'mb-3 w-100'
                )}
                onChange={(event) =>
                  handleChangePassword('passwordNew', event.target.value)
                }
                name="passwordNew"
                inputProps={{
                  type: 'password',
                  autoComplete: 'new-password',
                }}
              />
              <TextField
                error={false}
                label={t('update.renew_pass')}
                // defaultValue={passwordNew}
                variant="outlined"
                size="small"
                autoFocus={false}
                className={
                  isSubmitChangePassword &&
                  _.isEmpty(passwordNew2) &&
                  classes.errorInput
                }
                onChange={(event) =>
                  handleChangePassword('passwordNew2', event.target.value)
                }
                name="passwordNew2"
                inputProps={{
                  type: 'password',
                  autoComplete: 'new-password',
                }}
              />
            </FormControl>
          )}
          <div className={cn(classes.changePasswordButton, 'mt-3')}>
            {_.isEmpty(errorPassword) ? (
              isChangePasswordSucss != null && (
                <p className={classes.changePasswordStatus}>
                  {isChangePasswordSucss && (
                    <span className={classes.changePasswordSucc}>
                      {t('noti.change_password')}
                    </span>
                  )}
                  {!isChangePasswordSucss && (
                    <span className={classes.changePasswordErr}>
                      {t('noti.password_invalid')}
                    </span>
                  )}
                </p>
              )
            ) : (
              <p className={cn(classes.changePasswordStatus, 'mb-2')}>
                <span className={classes.changePasswordErr}>
                  {errorPassword}
                </span>
              </p>
            )}
            <ButtonComponent
              // className={classes.buttonChange}
              title={t('common.btn.cancel')}
              className="btn--rounder mr-2"
              backgroundColor="#BDBDBD"
              color="white"
              handleClickButton={() => handleClosePoup()}
            />
            <ButtonComponent
              className="btn--rounder"
              // className={classes.buttonChange}
              title={t('common.btn.reset_password')}
              backgroundColor="#FF8000"
              color="white"
              handleClickButton={() => handleSubmitChangePassword()}
            />
          </div>
        </div>
      </FormDialog>

      <FormDialog
        isOpen={isInviteCodePopup}
        handleClose={() => handleCloseInviteCodePopup()}
      >
        <div className={cn(classes.wrapChangePassword, 'p-3')}>
          <h4 className={classes.titleChangePassword}>
            {t('account_management.linked_account.invite_account.title')}
          </h4>
          <p className={classes.copySuccess}>{copySuccess}</p>
          {inviteCode && inviteCode.code ? (
            <p className="mb-2">
              {t(
                'account_management.linked_account.invite_account.description'
              )}
            </p>
          ) : (
            <div>
              <p>
                {messageInviteError != '' && messageInviteError != null
                  ? messageInviteError
                  : t('noti.limit_account')}
              </p>
            </div>
          )}
          <div className={classes.wrapTextareaCopy}>
            {inviteCode && inviteCode.code && (
              <>
                <textarea
                  className={cn(classes.textarea, 'p-3', 'w-100')}
                  ref={textAreaRef}
                  value={inviteCodeData}
                />
                <ButtonComponent
                  className={cn(classes.buttonChange, 'btn--rounder')}
                  title={t('common.btn.copy')}
                  backgroundColor="#FF8000"
                  color="white"
                  handleClickButton={() => copyToClipboard()}
                />
              </>
            )}
          </div>
        </div>
      </FormDialog>

      {isOpenTransfer && (
        <TransferAcc
          open={isOpenTransfer}
          handleClose={() => setIsOpenTransfer(false)}
          handleSubmit={() => setIsShowConfirmTransfer(true)}
          setTransferedId={setTransferedId}
          transferedId={transferedId}
        />
      )}

      <ConfirmModal
        open={isShowConfirmTransfer}
        handleCancel={() => setIsShowConfirmTransfer(false)}
        handleSubmit={handleTransferAccount}
        title={`Bạn có chắc chắn muốn chuyển đổi tài khoản chính ${user.id} sang tài
        khoản ${transferedId}, tất cả thông tin thời gian sử dụng và tài khoản
        liên kết sẽ được chuyển đổi sang tài khoản ${transferedId}`}
      />

      <FormDialog
        isOpen={isViewPolicyPoint || getOpenPoits == 1}
        handleClose={() => handleCloseIsViewPolicyPoint()}
      >
        <div className={cn(classes.wrapPolicyPoint, 'p-4')}>
          <h4 className={classes.titleVersion}>
            {t('account_management.tab_policy_point.title')}
          </h4>
          <br />
          <DialogPolicyPointContainer />
        </div>
      </FormDialog>
    </MainLayout>
  )
}

SettingAccountView.propTypes = {}

SettingAccountView.defaultProps = {}

export default SettingAccountView
