import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles, styled } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import moment from 'moment'
import types from '../constants/types'
import Pagination from '@material-ui/lab/Pagination'
import { TextField, Tooltip, IconButton, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import Flex from 'components/Flex'
import ButtonComponent from './Button'
import { pathKeys } from '../constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px 15px 15px 0px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    borderRadius: '8px',
    overflow: 'hidden',
    marginTop: '15px',
  },
  table: {
    minWidth: '100%',
  },
  tableHead: {
    backgroundColor: '#f5f5f5',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    color: '#333',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '12px 16px',
    minWidth: '150px',
  },
  ordNumberCell: {
    width: '80px',
    minWidth: '80px',
  },
  idCell: {
    width: '180px',
    minWidth: '180px',
  },
  nameCell: {
    width: '250px',
    minWidth: '250px',
  },
  pageNameCell: {
    width: '200px',
    minWidth: '200px',
  },
  creatorCell: {
    width: '180px',
    minWidth: '180px',
  },
  dateTimeCell: {
    width: '160px',
    minWidth: '160px',
  },
  statusCell: {
    width: '140px',
    minWidth: '140px',
    '& span': {
      padding: '6px 12px',
      borderRadius: '4px',
      fontWeight: '500',
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
    },
  },
  numberCell: {
    width: '120px',
    minWidth: '120px',
  },
  copyButton: {
    padding: 4,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  wrapperSearch: {
    width: 500,
    marginBottom: theme.spacing(2),
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '0.2px solid rgb(246, 239, 239)',
    backgroundColor: '#fafafa',
    position: 'sticky',
    right: 0,
    top: 0,
    zIndex: 1, // Adjust zIndex if necessary
  },
  actionButton: {
    background: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(0, 123, 255)',
    fontWeight: 600,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.2)',
    },
  },
  actionBtnDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 10px',
    borderRadius: '8px',
  },
  actionTitle: {
    margin: 0,
    fontSize: '10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const headCells = [
  {
    id: 'ord_number',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.ord_number',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.campaign_id',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.campaign_name',
  },
  {
    id: 'fanpage',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.page_name',
  },
  {
    id: 'author',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.creator',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.create_time',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.status',
  },
  {
    id: 'timeStart',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.start_time',
  },
  {
    id: 'numberCus',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.customer',
  },
  {
    id: 'totalMess',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.message',
  },
  {
    id: 'messSuccss',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.success',
  },
  {
    id: 'messFail',
    numeric: true,
    disablePadding: true,
    label: 'overall.lastest_campaign.failed',
  },
]

function EnhancedTableHead(props) {
  const { classes, t } = props

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          let cellClass = classes.tableCell
          switch (headCell.id) {
            case 'ord_number':
              cellClass = cn(classes.tableCell, classes.ordNumberCell)
              break
            case 'id':
              cellClass = cn(classes.tableCell, classes.idCell)
              break
            case 'name':
              cellClass = cn(classes.tableCell, classes.nameCell)
              break
            case 'fanpage':
              cellClass = cn(classes.tableCell, classes.pageNameCell)
              break
            case 'author':
              cellClass = cn(classes.tableCell, classes.creatorCell)
              break
            case 'createdAt':
            case 'timeStart':
              cellClass = cn(classes.tableCell, classes.dateTimeCell)
              break
            case 'status':
              cellClass = cn(classes.tableCell, classes.statusCell)
              break
            case 'numberCus':
            case 'totalMess':
            case 'messSuccss':
            case 'messFail':
              cellClass = cn(classes.tableCell, classes.numberCell)
              break
            default:
              break
          }

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              className={cn(cellClass, classes.tableHeadCell)}
            >
              <TableSortLabel hideSortIcon={true}>
                {t(headCell.label)}
              </TableSortLabel>
            </TableCell>
          )
        })}
        {/* <CellSticky
          align="center"
          className={[classes.widthActions, classes.pRow, classes.f600]}
          style={{
            borderLeft: '0.2px solid rgb(246, 239, 239)',
            boxShadow: 'none',
            backgroundColor: '#fafafa',
            position: 'sticky',
            right: 0,
            zIndex: 1,
          }}
        >
          <div>{t('common.btn.action')}</div>
        </CellSticky> */}
      </TableRow>
    </TableHead>
  )
}

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default function ListCampaign(props) {
  const { data, handleGetCampaign, getCampaign, isPagination, isSearch } = props
  const rows = (data && data.content) || []
  const classes = useStyles()
  const { t } = useTranslation()

  const history = useHistory()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(types.PAGINATION.SIZE)
  const [search, setSearch] = useState('')
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback((id) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }, [])

  useEffect(() => {
    handleSubmitSearch()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    handleGetCampaign({
      page: newPage,
      size: rowsPerPage,
      search: search || undefined,
      sort: 'createdAt,desc',
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(types.PAGINATION.PAGE)
    handleGetCampaign({
      page: types.PAGINATION.PAGE,
      size: newRowsPerPage,
      search: search || undefined,
      sort: 'createdAt,desc',
    })
  }

  const handleChange = (event, value) => {
    setPage(value - 1)
    handleGetCampaign({
      page: value - 1,
      size: rowsPerPage,
      search: search || undefined,
      sort: 'createdAt,desc',
    })
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleSubmitSearch = () => {
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      sort: 'createdAt,desc',
    }
    if (search) params.search = `name|pageNameText==${search}`
    getCampaign(params)
    setRowsPerPage(types.PAGINATION.SIZE)
    setPage(types.PAGINATION.PAGE)
  }

  const onViewScenario = useCallback((id) => {
    history.push({
      pathname: pathKeys.SCENARIO,
      search: `?campaignId=${id}`,
    })
  }, [])

  const onViewCampaign = useCallback((id) => {
    window.open(
      `${types.URL.FACEBOOK}&token=${
        localStorage.getItem('token') || null
      }&campaign=${id}`,
      '_blank'
    )
  }, [])

  const getStatusStyle = (status) => {
    switch (status) {
      case 'done':
        return { backgroundColor: '#e8f5e9', color: '#2e7d32' }
      case 'pending':
        return { backgroundColor: '#fff3e0', color: '#e65100' }
      case 'cancel':
        return { backgroundColor: '#ffebee', color: '#c62828' }
      case 'running':
        return { backgroundColor: '#e3f2fd', color: '#1565c0' }
      default:
        return { backgroundColor: '#f5f5f5', color: '#333' }
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmitSearch()
    }
  }

  return (
    <>
      <div className={classes.root}>
        {isSearch && (
          <Flex align="center" auto>
            <TextField
              variant="outlined"
              size="small"
              onChange={handleSearch}
              placeholder={t('campaign_management.search')}
              fullWidth
              onKeyDown={onKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <ButtonComponent
              id="search"
              className={cn(
                classes.buttonFilter,
                'btn--rounder',
                'btn--primary--filled',
                'mx-3'
              )}
              title={
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                  }}
                >
                  {t('common.btn.search')}
                </span>
              }
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitSearch()}
              style={{
                minWidth: 'fit-content',
                padding: '6px 16px',
              }}
            />
          </Flex>
        )}
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} t={t} />
              <TableBody>
                {rows.map((row, index) => {
                  if (row.totalCus === 0) return null
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => onViewCampaign(row.id)}
                      className={classes.clickableRow}
                    >
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.ordNumberCell)}
                      >
                        {data?.number * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.idCell)}
                      >
                        <Flex justify="space-between" align="center">
                          <span className="text-truncate">{row.id}</span>
                          <Tooltip title={copied ? t('copied') : t('copy')}>
                            <IconButton
                              className={classes.copyButton}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleCopy(row.id)
                              }}
                              size="small"
                            >
                              <img
                                src="/image/Clipboard-alt.svg"
                                alt="Copy"
                                style={{ width: '16px', height: '16px' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Flex>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.nameCell)}
                      >
                        <span className="text-truncate">{row.name}</span>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.pageNameCell)}
                      >
                        {row.pageName}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.creatorCell)}
                      >
                        {row.userFullName}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.dateTimeCell)}
                      >
                        {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.statusCell)}
                      >
                        <span style={getStatusStyle(row.status)}>
                          {(() => {
                            switch (row.status) {
                              case 'done':
                                return t(
                                  'overall.lastest_campaign.campaign_status.complete'
                                )
                              case 'pending':
                                return t(
                                  'overall.lastest_campaign.campaign_status.sent_later'
                                )
                              case 'cancel':
                                return t(
                                  'overall.lastest_campaign.campaign_status.pause'
                                )
                              case 'running':
                                return t('overall.campaign_and_message.sending')
                              default:
                                return row.status
                            }
                          })()}
                        </span>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.dateTimeCell)}
                      >
                        {moment(row.beginTime).format('DD/MM/YYYY HH:mm')}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                      >
                        {row.totalCus}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                      >
                        {row.totalMes}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                      >
                        {row.totalSuccess}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                      >
                        {row.totalFail}
                      </TableCell>

                      {/* <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderLeft: "0.2px solid rgb(246, 239, 239)",
                          boxShadow: "none",
                          backgroundColor: "#fafafa",
                          position: "sticky",
                          right: 0,
                          top: 0,
                          zIndex: 1
                        }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling
                          onViewScenario(row.id); // Call the function when the cell is clicked
                        }}
                      >
                        <Tooltip title={t("campaign_management.scenario.detail")}>
                          <Button
                            className={classes.actionButton}
                            style={{
                              width: "100%", // Make the button full width
                              height: "100%", // Make the button full height
                              padding: 0 // Remove padding
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event bubbling
                              onViewScenario(row.id); // Call the function when the button is clicked
                            }}
                          >
                            <div className={classes.actionBtnDetail}>
                              <p className={classes.actionTitle}>
                                {t("campaign_management.scenario.name")}
                              </p>
                            </div>
                          </Button>
                        </Tooltip>
                      </TableCell> */}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length === 0 && (
            <p className={classes.noData}>{t('update.no_data')}</p>
          )}
          {isPagination && rows.length > 0 && (
            <Flex justify="space-between" align="center" className="px-3 py-2">
              <TablePagination
                rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
                component="div"
                count={parseInt(data && data.totalElements)}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('update.display')}
              />
              <Pagination
                count={data.totalPages + 1}
                page={page + 1}
                onChange={handleChange}
              />
            </Flex>
          )}
        </Paper>
      </div>
    </>
  )
}

ListCampaign.propTypes = {
  data: PropTypes.object,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getCampaign: PropTypes.func,
  handleGetCampaign: PropTypes.func,
}

ListCampaign.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetCampaign: () => {},
  getCampaign: () => {},
  data: {},
  search: null,
}
