import i18n from 'utils/i18n'
import axiosClient from './axiosClient'
import urlApi from './url'
const homeApi = {
  updateUser: (params, uid) => {
    return axiosClient.put(`${urlApi.user.user}/${uid}`, params)
  },
  verifyNumberPhone: (params) => {
    return axiosClient.get(`${urlApi.user.verifyPhone}`, { params })
  },
  getOTP: (params) => {
    return axiosClient.get(`${urlApi.user.getOTP}`, { params })
  },
  getWhatYouNeed: (params) => {
    return axiosClient.get(`${urlApi.shared.getWhatYouNeed}`, {
      params: {
        ...params,
        lang:
          i18n?.language === 'en-US' || !i18n?.language ? 'en' : i18n?.language,
      },
    })
  },
  getDashboard: (params) => {
    return axiosClient.get(`${urlApi.shared.getDashboard}`, { params })
  },

  getMessageStatistic: (params) => {
    return axiosClient.get(`${urlApi.dashboard.getMessageStatistic}`, {
      params,
    })
  },

  getCampaignStatistic: (params) => {
    return axiosClient.get(`${urlApi.dashboard.getCampaignStatistic}`, {
      params,
    })
  },

  getFanpageStatistic: (params) => {
    return axiosClient.get(`${urlApi.dashboard.getFanpageStatistic}`, {
      params,
    })
  },

  getCustomerStatistic: (params) => {
    return axiosClient.get(`${urlApi.dashboard.getCustomerStatistic}`, {
      params,
    })
  },

  getGenAIStatistic: (params) => {
    return axiosClient.get(`${urlApi.dashboard.getGenAIStatistic}`, {
      params,
    })
  },
}

export default homeApi
