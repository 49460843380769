import React, { useEffect } from 'react'
import firebase from 'firebase'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { selectUser } from '../../views/App/AppSelectors'
import { vapidKey } from '../../constants/firebase'
import { accountApi } from '../../api/accountApi'
import { showError, showSuccess } from '../../stores/alert/slice.alert'
import { pathKeys } from '../../constants'

const NotiPermission = ({ children }) => {
  const messaging = firebase.messaging()

  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  const requestForToken = () => {
    return messaging
      .getToken(messaging, {
        vapidKey: vapidKey,
      })
      .then((currentToken) => {
        return currentToken
      })
  }

  function getUserIdFromURL() {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    return params.get('userId')
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          return requestForToken()
        })
        .then((notiToken) => {
          const userId = getUserIdFromURL() || user?.id

          if (user?.tokenBrowser) return

          if (!userId) return

          const payload = {
            userId,
            token: notiToken,
          }

          console.log('component ==>')

          // return accountApi.updateNotiPermissionToken(payload)
        })
        .then((res) => {})
        .catch((error) => {
          if (
            error.message ===
            "Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker"
          ) {
            window.location.reload()
          }
          console.error('Service Worker registration failed:', error)
        })
    }
  }, [user?.id])

  return <>{children}</>
}

export default NotiPermission
