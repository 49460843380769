import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import './Root.css'
import App from './views/App'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from './GlobalStyles'
import theme from './theme'
import {
  MuiThemeProvider,
  CssBaseline,
  responsiveFontSizes,
} from '@material-ui/core'
import firebase from 'firebase'
import _ from 'lodash'
import { vapidKey } from './constants/firebase'
import NotiPermission from './components/NotiPermission'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBhGWBekJgaa-YtWTSj000lxDS6t0MPiZE',
    authDomain: 'bombot-296af.firebaseapp.com',
    projectId: 'bombot-296af',
    storageBucket: 'bombot-296af.appspot.com',
    messagingSenderId: '641430483635',
    appId: '1:641430483635:web:ba98b284f0464f9d2a36c6',
    measurementId: 'G-L07FB3BXTW',
  })
}
/**
 * @return {null}
 */

function Root(props) {
  const messaging = firebase.messaging()

  const { store } = props
  const responsiveFSTheme = responsiveFontSizes(theme())

  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={responsiveFSTheme}>
          <CssBaseline />
          <GlobalStyles />
          <NotiPermission>
            <App />
          </NotiPermission>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default Root
