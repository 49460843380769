import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import cn from 'classnames'
import Flex from 'components/Flex'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { selectFanpageSelected } from 'views/Customer/CustomerSelectors'
import _ from 'lodash'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import EditIcon from '@material-ui/icons/Edit'
import fanpageApi from '../../api/fanpageApi'
import styled from 'styled-components'
import { X } from 'react-feather'
import moment from 'moment'
import TagPreviewItem from 'components/TagPreviewItem'
import LoadingWrapper from '../LoadingWrapper'
import { showError, showSuccess } from '../../stores/alert/slice.alert'
import customerApi from '../../api/customerApi'
import types from '../../constants/types'
import Confirm from 'components/Modals/Confirm'
import useDebounce from '../../hook/useDebouce'
import { ArrowBack } from '@material-ui/icons'
import ButtonComponent from 'components/Button'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: '80px',
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  '.pagination': {
    '& .MuiTablePagination-input': {
      width: 70,
    },
  },

  wrapperSearch: {
    position: 'relative',
  },

  buttonIEA: {
    width: '100%',
    // marginTop: 10,
    textAlign: 'left',
    marginRight: '15px',
    padding: '20px 15px 15px 0px',
    position: 'fixed',
    bottom: 60,
    background: '#F6F9FC',
    '& button': {
      marginRight: 15,
    },
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '24px',
  },
  iconCheck: {
    '& .MuiSvgIcon-root': {
      width: '40',
      height: '40',
    },
  },
  icon: {
    marginLeft: 5,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: '40',
      height: '40',
    },
  },
  classTags: {
    '&:last-child': {
      '& span': {
        display: 'none',
      },
    },
  },
  wrapBox: {
    padding: '15px',
    marginBottom: 30,
  },
  inputAdd: {
    width: '100%',
    marginBottom: 15,
  },
  wrapButton: {
    textAlign: 'center',
  },
  buttonLoadMore: {
    border: '0px',
    color: 'var(--primary-color) !important',
    backgroundColor: 'unset',
  },

  witdhSeq: {
    minWidth: '120px',
    width: '4%',
  },

  witdhCheckBox: {
    minWidth: '60px',
    width: '4%',
  },

  witdhUid: {
    minWidth: '160px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthName: {
    minWidth: '160px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthGender: {
    minWidth: '120px',
    width: '6%',
    padding: '0 8px !important',
  },

  widthPhone: {
    minWidth: '160px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthDate: {
    minWidth: '200px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthLinkFB: {
    minWidth: '120px',
    width: '15%',
    padding: '0 8px !important',
  },

  witdhAction: {
    minWidth: '120px',
    width: '4%',
  },

  widthTag: {
    minWidth: '140px',
    width: '15%',
    padding: '12px 8px !important',
  },

  witdhUidErr: {
    minWidth: '140px',
    width: '20%',
    padding: '0 8px !important',
  },

  p0: {
    padding: '0',
    margin: '0',
  },

  heightHeader: {
    height: '52px',
  },

  f600: {
    fontWeight: 600,
  },

  checkBoxPadding: {
    padding: '10px 20px',
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '18px 0 8px',
  },

  closeBtn: {
    color: '#ffffff', // Màu chữ trắng
    backgroundColor: '#dc3545', // Màu nền đỏ để nổi bật
    border: '2px solid #dc3545', // Viền có màu giống nền
    padding: '6px 20px', // Padding cho nút
    borderRadius: '4px', // Đường viền tròn cho nút
    cursor: 'pointer', // Con trỏ chuột khi hover
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#c82333', // Màu nền khi hover (đỏ đậm hơn)
      borderColor: '#bd2130', // Màu viền khi hover
    },
  },
  dialogTitle: {
    position: 'relative',
    paddingRight: '48px',
    margin: 0,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: '8px',
    marginRight: '8px',
    color: '#637381',
    '&:hover': {
      color: '#212B36',
      backgroundColor: 'rgba(99, 115, 129, 0.08)',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#637381',
    padding: '8px',
    marginRight: '8px',
    '&:hover': {
      color: '#212B36',
      backgroundColor: 'rgba(99, 115, 129, 0.08)',
      borderRadius: '50%',
    },
  },

  submitBtn: {
    color: 'white', // Màu chữ
    backgroundColor: '#28a745', // Màu nền
    border: '2px solid #28a745', // Viền
    padding: '6px 26px', // Padding cho nút
    borderRadius: '4px', // Đường viền tròn cho nút
    cursor: 'pointer', // Con trỏ chuột khi hover.
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#218838', // Màu nền khi hover
      borderColor: '#1e7e34', // Màu viền khi hover
    },
  },

  numCusInp: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },

  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '12px 0',
    minHeight: '48px',
  },

  container: {
    flex: 1,
    width: '100%',
  },

  paginationBottom: {
    display: 'flex !important',
    justifyContent: 'end !important',
    alignItems: 'center !important',
  },

  buttonLoadMore: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: '#fff',
    border: '1px solid #dbdbdb',
    borderRadius: '.375em',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#363636',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: `BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    fontSize: '0.8rem',
    height: '1.8em',
    justifyContent: 'center',
    lineHeight: '1.5',
    padding: 'calc(.5em - 1px) 1em',
    position: 'relative',
    textAlign: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    touchAction: 'manipulation',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    width: '100%',

    '&:hover': {
      borderColor: '#b5b5b5',
    },
    '&:active': {
      borderColor: '#4a4a4a',
      outline: '0',
    },
    '&:focus': {
      borderColor: '#485fc7',
      outline: '0',
    },
    '&:focus:not(:active)': {
      boxShadow: 'rgba(72, 95, 199, 0.25) 0 0 0 0.125em',
    },
  },

  rowItem: {
    cursor: 'pointer',
  },

  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    padding: '4px 0 !important',
  },
}))
const countRowPerPages = 10

const CustomersInFBTagModal = ({ isOpen, handleClose, tagId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const pageSelected = useSelector(selectFanpageSelected)
  const [page, setPage] = useState(0)
  const [customerData, setCustomerData] = useState(null)
  const [currentCustomers, setCurrentCustomers] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [checkedList, setCheckedList] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)

  const dispatch = useDispatch()

  const getListCustomers = async (page, searchValue = '') => {
    let searchQuery = `pageId==${pageSelected?.id};fbTags=in=${tagId}`

    if (searchValue.trim()) {
      searchQuery += `;uid|name==${searchValue}`
    }

    return customerApi
      .getListCustomersInTag({
        search: searchQuery,
        page: page,
        size: countRowPerPages,
      })
      .then((res) => {
        setCustomerData(res)
      })
  }

  // useDebounce(
  //   () => {
  //     ;(async () => {
  //       setIsLoading(true)
  //       try {
  //         setPage(0)
  //         if (!searchVal.trim()) {
  //           await getListCustomers(0)
  //           return
  //         }
  //         await getListCustomers(0, searchVal)
  //       } catch (error) {
  //         console.log('error', error)
  //         dispatch(showError('Có lỗi xảy ra khi tìm kiếm'))
  //       } finally {
  //         setIsLoading(false)
  //       }
  //     })()
  //   },
  //   500,
  //   [searchVal]
  // )

  const handleSearch = async () => {
    setIsLoading(true)
    try {
      setPage(0)
      if (!searchVal.trim()) {
        await getListCustomers(0)
        return
      }
      await getListCustomers(0, searchVal)
    } catch (error) {
      console.log('error', error)
      dispatch(showError('Có lỗi xảy ra khi tìm kiếm'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)

        await getListCustomers(page)
      } catch (error) {
        dispatch(showError('Co loi xay ra!'))
      } finally {
        setIsLoading(false)
      }
    })()
  }, [tagId, page])

  const handleCheckAllTag = (isChecked) => {
    if (isChecked) {
      let checkListTmp = {}
      customerData?.content.forEach((customer) => {
        checkListTmp[customer.uid] = isChecked
      })
      setCheckedList(checkListTmp)
    } else {
      setCheckedList({})
    }
  }

  const handleCheckTag = (isChecked, uid) => {
    let checkListTmp = { ...checkedList }

    if (isChecked) {
      setCheckedList((prev) => ({
        ...prev,
        [uid]: isChecked,
      }))
    } else {
      delete checkListTmp[uid]
      setCheckedList(checkListTmp)
    }
  }

  const handleDelete = async () => {
    if (!pageSelected?.id) return
    setIsLoading(true)
    try {
      const payload = {
        search: `pageId==${pageSelected?.id}`,
        exclude: [],
        include: Object.keys(checkedList),
        fbTags: [tagId],
      }

      await customerApi.unlinkBombotTag(payload)
      await getListCustomers(0)
      setOpenConfirm(false)
      dispatch(
        showSuccess(
          `Đã xóa tag khỏi ${
            Object.keys(checkedList).length
          } khách hàng thành công`
        )
      )
      setCheckedList({})
    } catch (error) {
      console.log('error', error)
      dispatch(showError('Xóa tag thất bại. Vui lòng thử lại sau'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitDelete = () => {
    if (Object.keys(checkedList).length === 0) {
      dispatch(showError('Vui lòng chọn ít nhất một khách hàng'))
      return
    }
    setOpenConfirm(true)
  }

  const handleDeleteSearch = () => {
    setSearchVal('')
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <Dialog open={isOpen} maxWidth="lg">
      <LoadingWrapper isOpen={isLoading}>
        <DialogTitle className={classes.dialogTitle}>
          <Flex align="center">
            {/* <IconButton onClick={handleClose} className={classes.backButton}>
              <ArrowBack />
            </IconButton> */}
            <span>{t('tag_management.customers.list')}</span>
          </Flex>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Flex align="center" auto>
            <TextField
              variant="outlined"
              size="small"
              placeholder={t('tag_management.customers.search_plh')}
              className="w-100 bg-white"
              id="input-with-icon-textfield"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: '#637381' }} />
                  </InputAdornment>
                ),
                endAdornment: searchVal && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleDeleteSearch}
                      size="small"
                      style={{
                        padding: 4,
                        marginRight: -8,
                      }}
                    >
                      <X
                        size={16}
                        style={{ color: '#637381', marginRight: 4 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  paddingRight: 8,
                },
              }}
              style={{
                width: '100%',
                marginRight: '10px', // Thêm margin để tạo khoảng cách với button
              }}
            />
            <ButtonComponent
              id="search"
              className={cn(
                classes.buttonFilter,
                'btn--rounder',
                'btn--primary--filled',
                'mx-3'
              )}
              title={
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                  }}
                >
                  {t('common.btn.search')}
                </span>
              }
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={handleSearch}
              style={{
                minWidth: 'fit-content',
                padding: '6px 16px',
              }}
            />
          </Flex>

          <TableContainer
            component={Paper}
            style={{
              boxShadow: 'none',
              height: '54vh',
              marginTop: '20px',
              width: '1000px',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={[classes.p0, classes.widthCheckBox]}>
                    <FormControlLabel
                      className={[classes.p0, classes.checkBoxPadding]}
                      control={
                        <Checkbox
                          className={classes.p0}
                          checked={customerData?.content.every(
                            (cus) => checkedList[cus.uid]
                          )}
                          onChange={(_, isChecked) => {
                            handleCheckAllTag(isChecked)
                          }}
                          disabled={true}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={[classes.witdhUid, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.ord_number')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={[classes.witdhUid, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.uid')}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={[classes.widthName, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.facebook_name')}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={[classes.widthGender, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.gender')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={[classes.widthDate, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.last_inbox_time')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={[classes.widthDate, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.last_customer_inbox_time')}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={[classes.widthPhone, classes.p0, classes.f600]}
                  >
                    {t('customer_management.list.phone')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerData?.content.map((row, index) => (
                  <TableRow
                    key={index}
                    className={[classes.heightHeader, classes.rowItem]}
                  >
                    <TableCell
                      align="center"
                      className={[classes.p0, classes.widthCheckBox]}
                    >
                      <FormControlLabel
                        className={[classes.p0, classes.checkBoxPadding]}
                        control={
                          <Checkbox
                            className={classes.p0}
                            checked={checkedList[row.uid] ? true : false}
                            onChange={(_, isChecked) => {
                              handleCheckTag(isChecked, row.uid)
                            }}
                            disabled={true}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.witdhUid, classes.p0]}
                    >
                      <div>{index + 1}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.witdhUid, classes.p0]}
                    >
                      {row.uid ? (
                        <div>{row.uid}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthName, classes.p0]}
                    >
                      {row.name ? (
                        <div>{row.name}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      style={{
                        minWidth: '86px',
                      }}
                      align="center"
                      className={[classes.widthGender, classes.p0]}
                    >
                      {row.gender ? (
                        <div>
                          {_.upperCase(row.gender) ==
                          _.upperCase(types.GENDER.MALE)
                            ? t('update.gender.male')
                            : _.upperCase(row.gender) ==
                              _.upperCase(types.GENDER.FEMALE)
                            ? t('update.gender.female')
                            : t('update.gender.other')}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.lastInboxTime ? (
                        <div>
                          {moment(row.lastInboxTime).format('DD/MM/YYYY HH:mm')}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.lastCustomerInboxTime ? (
                        <div>
                          {moment(row.lastCustomerInboxTime).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthPhone, classes.p0]}
                    >
                      {row.phone ? (
                        <div>{row.phone}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {customerData?.content?.length === 0 && (
              <p className={classes.noData}>{t('update.no_data')}</p>
            )}
          </TableContainer>

          <DialogActions className={classes.actions}>
            <Pagination
              style={{ position: 'unset' }}
              count={customerData?.totalPages + 1}
              page={page + 1}
              onChange={(event, newPage) =>
                handleChangePage(event, newPage - 1)
              }
              color="secondary"
            />
            <Flex align="center" justify="flex-end">
              <Tooltip title={t('common.btn.feature_not_active')}>
                <span>
                  <Button
                    size="small"
                    type="submit"
                    className="btn--rounder btn--primary--filled"
                    onClick={handleSubmitDelete}
                    disabled
                  >
                    <Flex align="center">
                      <p>{t('common.btn.delete')}</p>
                    </Flex>
                  </Button>
                </span>
              </Tooltip>
            </Flex>
          </DialogActions>
        </DialogContent>
      </LoadingWrapper>

      <Confirm
        open={openConfirm}
        title="Xác nhận xóa tag"
        content={`Bạn có chắc chắn muốn xóa tag khỏi ${
          Object.keys(checkedList).length
        } khách hàng đã chọn?`}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDelete}
        confirmText="Xóa"
        cancelText="Hủy"
      />
    </Dialog>
  )
}

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: -1px;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`

export default CustomersInFBTagModal
