import { Card, CardContent } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Bar, Doughnut } from 'react-chartjs-2'
import styles from '../../styles.module.scss' // Ensure you have this file
import { useEffect } from 'react'
import CampaignChart from './CampaignChart'
import MessageChart from './MessageChart'
import CustomerChart from './CustomerChart'
import GenAIChart from './GenAIChart'
import Flex from '../../../../components/Flex'
import FanpageChart from './FanpageChart'

const DashboardChart = ({
  dashboard,
  campaigns,
  messages,
  scenarios,
  selectedDate = null,
  onDateChange,
}) => {
  const dataCampaigns = {
    labels: [
      'Campaign Running',
      'Campaign Pending',
      'Campaign Done',
      'Campaign Cancelled',
    ],
    datasets: [
      {
        data: [
          dashboard?.campaign?.totalCampaignRunning || 0,
          dashboard?.campaign?.totalCampaignPending || 0,
          dashboard?.campaign?.totalCampaignDone || 0,
          dashboard?.campaign?.totalCampaignCancel || 0,
        ],
        backgroundColor: ['#82ca9d', '#ffc658', '#8884d8', '#AE1607'],
        hoverBackgroundColor: ['#76b89a', '#e6b84d', '#7a6fb8', '#9e1410'],
      },
    ],
  }

  const dataMessages = {
    labels: ['Message', 'Message Fail', 'Message Not Send', 'Message Success'],
    datasets: [
      {
        data: [
          messages?.totalMessages || 0,
          messages?.totalMessagesFail || 0,
          messages?.totalMessagesNotSend || 0,
          messages?.totalMessagesSuccess || 0,
        ],
        backgroundColor: ['#82ca9d', '#ffc658', '#8884d8', '#AE1607'],
        hoverBackgroundColor: ['#76b89a', '#e6b84d', '#7a6fb8', '#9e1410'],
      },
    ],
  }

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }

  // Check if dashboard has data
  if (!dashboard || !dashboard.fanpage || !campaigns) {
    return <div>Loading...</div> // Or another loading message
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      flexWrap="wrap"
      row
      className={styles.box}
      style={{
        padding: '15px 15px 15px 0',
      }}
    >
      {/* Campaigns */}
      {/* <CardContent>
        <h3>Chiến dịch</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ width: '30%', height: '300px' }}>
            <Doughnut data={dataCampaigns} options={doughnutOptions} />
          </div>
        </div>
      </CardContent>

      <CardContent>
        <h3>Tin nhắn</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ width: '30%', height: '300px' }}>
            <Doughnut data={dataMessages} options={doughnutOptions} />
          </div>
        </div>
      </CardContent>

      <CardContent>
        <h3>Kịch bản</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ width: '30%', height: '300px' }}>
            <Doughnut data={dataMessages} options={doughnutOptions} />
          </div>
        </div>
      </CardContent> */}

      <CampaignChart
        style={{
          width: '48%',
          padding: '16px',
          marginBottom: '32px',
        }}
      />

      <MessageChart
        style={{
          width: '48%',
          padding: '16px',
          marginBottom: '32px',
        }}
      />

      <CustomerChart
        style={{
          width: '48%',
          padding: '16px',
          marginBottom: '32px',
        }}
      />

      <GenAIChart
        style={{
          width: '48%',
          padding: '16px',
          marginBottom: '32px',
        }}
      />

      <FanpageChart
        style={{
          width: '100%',
          padding: '16px',
        }}
      />

      {/* Fanpages and Customers */}
    </Flex>
  )
}

export default DashboardChart
