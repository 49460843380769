import ReactDOM from 'react-dom'
import Root from './Root'
import './assets/styles/common.scss'
import './assets/styles/normalize.css'
import { initFacebookSdk } from './configs/init-facebook-sdk'
import store from './configs/redux/store'
import * as serviceWorker from './serviceWorker'

initFacebookSdk()

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

serviceWorker.unregister()
