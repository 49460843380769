import { useEffect, useMemo, useState } from 'react'
import scenarioApi from '../../../api/scenarioApi'

import {
  IconButton,
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { Autocomplete, Pagination } from '@material-ui/lab'
import CampaignStatusLabel, { campaignStatusDir } from './CampaignStatusLabel'
import CoppyIcon from './CoppyIcon'

import Flex from '../../../components/Flex'
import TimeLabel from './TimeLabel'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { campaign } from '../../Campaign/CampaignSelectors'
import { setCampaign } from '../../Campaign/CampaignReducer'

const defaultSizeGetScenario = 50

const useStyles = makeStyles((theme) => ({
  witdhOrd: {
    minWidth: '140px',
    padding: '0 8px !important',
  },

  witdhId: {
    minWidth: '170px',
    padding: '0 8px !important',
  },

  widthName: {
    minWidth: '160px',
    padding: '0 8px !important',
  },

  widthCreator: {
    minWidth: '160px',
    padding: '0 8px !important',
  },

  widthDate: {
    minWidth: '160px',
    padding: '0 8px !important',
  },

  widthStatisticBtn: {
    minWidth: '170px',
    padding: '0 8px !important',
  },

  widthStatistic: {
    minWidth: '180px',
    padding: '0 8px !important',
  },

  widthActions: {
    minWidth: '180px',
    padding: '0 8px !important',
  },

  widthStatus: {
    minWidth: '130px',
    padding: '0 8px !important',
  },

  p0: {
    padding: '0',
    margin: '0',
  },

  heightHeader: {
    height: '52px',
  },

  f600: {
    fontWeight: 600,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '10px 26px 16px 0',
  },

  actionBtnRemove: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#ff4d4f', // Red color for delete button
    fontWeight: 600,
    padding: '6px 12px',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease', // Transition for smooth effect
    '&:hover': {
      backgroundColor: 'rgba(255, 77, 79, 0.1)', // Light red background on hover
    },
  },

  actionBtnDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'rgb(0, 123, 255)', // Red color for delete button
    fontWeight: 600,
    padding: '6px 10px',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease', // Transition for smooth effect
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.2)', // Light red background on hover
    },
  },

  actionSend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#52c41a', // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: '8px 12px',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease', // Hiệu ứng chuyển mượt
    '&:hover': {
      backgroundColor: 'rgba(82, 196, 26, 0.1)', // Nền xanh lá nhạt khi hover
    },
  },

  actionAdd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#636ae8', // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease', // Hiệu ứng chuyển mượt
    '&:hover': {
      backgroundColor: 'rgba(99, 106, 232, 0.1)', // Nền xanh lá nhạt khi hover

      // backgroundColor: 'rgba(82, 196, 26, 0.1)', // Nền xanh lá nhạt khi hover
    },
  },

  actionBtnSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#007bff', // Blue color similar to "Bombot Tags" text
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease', // Transition for smooth effect
    backgroundColor: 'rgb(230, 243, 255, 0.5)',
    '&:hover': {
      backgroundColor: 'rgb(230, 243, 255, 0.8)', // Light blue background color
    },
    border: '1px solid #a3c1dd',
  },

  actionIcon: {
    fontSize: '14px',
  },
  actionTitle: {
    margin: 0,
    fontSize: '10px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  rowItem: {
    cursor: 'pointer',
  },
}))

const ScenarioList = ({ campaignId }) => {
  const classes = useStyles()

  const [scenarioList, setScenarioList] = useState(null)
  const [isCopiedId, setIsCopiedId] = useState(false)
  const [page, setPage] = useState(0)
  const [campaignSelected, setCampaignSelected] = useState(campaignId)

  const campaigns = useSelector(campaign)

  useEffect(() => {
    console.log(
      'campaigns ==>',
      campaigns,
      campaigns?.content?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    )
  }, [campaigns])

  useEffect(() => {
    ;(async () => {
      const searchParam = new URLSearchParams(window.location.search)

      console.log('searchParam ==>', searchParam)

      try {
        const res = await scenarioApi.get({
          id: campaignSelected,
          page,
          sort: 'createdAt,asc',
        })
        setScenarioList(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [campaignSelected])

  useEffect(() => {
    setCampaignSelected(campaignId)
  }, [campaignId])

  const handleOpenScenarioDetail = (scenarioId = null) => {}

  const handleChangePage = async (event, newPage) => {
    setPage(newPage)
  }

  const options = useMemo(() => {
    return (
      campaigns?.content?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    )
  }, [campaigns])

  return (
    <div
      id="scenario_list"
      style={{
        height: '380px',
      }}
    >
      <Autocomplete
        style={{
          margin: '24px 0',
        }}
        fullWidth
        name="num_of_variations"
        freeSolo
        variant="outlined"
        disablePortal
        options={options}
        getOptionLabel={(option) => option.label} // Chỉ định nhãn hiển thị
        isOptionEqualToValue={(option, value) => option.value === value.value} // So sánh giá trị
        value={{ value: campaignSelected, label: campaignSelected }}
        onChange={(event, value) => {
          console.log('value ==>', value)
          setCampaignSelected(value?.value)
        }}
        onChangeCapture={(e) => {
          // handleInputChange('num_of_variations', {
          //   value: e.target.value,
          //   label: e.target.value,
          // })
        }}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Chọn Chiến Dịch'}
            variant="outlined"
            size="small"
          />
        )}
      />
      <TableContainer
        component={Paper}
        style={{
          boxShadow: 'none',
          height: '100%',
          width: '100%',
          border: '1px solid rgb(222, 225, 230)',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow
              className={[classes.heightHeader]}
              style={{
                width: 'max-content',
              }}
            >
              <TableCell
                align="center"
                className={[classes.witdhOrd, classes.p0, classes.f600]}
              >
                <div>Ord Num</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.witdhId, classes.p0, classes.f600]}
              >
                <div>Scenario Id</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthName, classes.p0, classes.f600]}
              >
                <div>Scenario Name</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthCreator, classes.p0, classes.f600]}
              >
                <div>Creator</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthDate, classes.p0, classes.f600]}
              >
                <div>Created At</div>
              </TableCell>
              <TableCell
                align="center"
                className={[
                  classes.widthStatisticBtn,
                  classes.p0,
                  classes.f600,
                ]}
              >
                <div>Customers</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Customers Success</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Customers Failed</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Customers Not Send</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Messages</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Messages Success</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Messages Failed</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatistic, classes.p0, classes.f600]}
              >
                <div>Messages Not Send</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatus, classes.p0, classes.f600]}
              >
                <div>Sending Status</div>
              </TableCell>
              <TableCell
                align="center"
                className={[classes.widthStatus, classes.p0, classes.f600]}
              >
                <div>Schedule Status</div>
              </TableCell>

              <CellSticky
                align="center"
                className={[classes.widthActions, classes.pRow, classes.f600]}
                style={{
                  borderLeft: '0.2px solid rgb(246, 239, 239)',
                  boxShadow: 'none',
                  backgroundColor: '#fafafa',
                  position: 'sticky',
                  right: 0,
                  zIndex: 99,
                }}
              >
                <div>Actions</div>
              </CellSticky>
            </TableRow>
          </TableHead>

          <TableBody>
            {scenarioList?.content?.map((row, index) => (
              <TableRow
                key={row.id}
                className={[classes.heightHeader, classes.rowItem]}
              >
                <TableCell
                  align="center"
                  className={[classes.witdhOrd, classes.p0]}
                >
                  <div>
                    {scenarioList?.pageable?.pageNumber *
                      defaultSizeGetScenario +
                      index +
                      1}
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.witdhId, classes.p0]}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Tooltip title={row.id} aria-label="add">
                      <div
                        style={{
                          maxWidth: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {row.id}
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={isCopiedId ? 'Copied!' : 'Copy'}
                      aria-label="add"
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          navigator.clipboard.writeText(row.id)
                          setIsCopiedId(true)
                          setTimeout(() => {
                            setIsCopiedId(false)
                          }, 1000)
                        }}
                        size="small"
                        style={{ marginLeft: '14px' }}
                      >
                        <CoppyIcon height={'20px'} width={'20px'} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthName, classes.p0]}
                >
                  {row.name ? (
                    <div>{row.name}</div>
                  ) : (
                    <p
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                      }}
                    >
                      {'_'}
                    </p>
                  )}
                </TableCell>

                <TableCell
                  style={{
                    minWidth: '86px',
                  }}
                  align="center"
                  className={[classes.widthCreator, classes.p0]}
                >
                  {row.createdByUserId ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                      }}
                    >
                      <p
                        style={{
                          width: '100px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '14px',
                        }}
                      >
                        {row.userCreated}
                      </p>
                      {/* <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation()
                            setCreatorShowUp(null)
                          }}
                        >
                          <HtmlTooltip
                            placement="top"
                            open={creatorShowUp?.scenarioId === row.id}
                            interactive={true}
                            title={
                              <UserInfoCard
                                scenarioId={creatorShowUp?.scenarioId}
                              />
                            }
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: '14px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                console.log(row.createdByUserId)
                                setCreatorShowUp((prev) => {
                                  if (row?.id === prev?.scenarioId) {
                                    return null
                                  }
                                  return {
                                    id: row.createdByUserId,
                                    scenarioId: row.id,
                                  }
                                })
                              }}
                            >
                              <PermIdentity height={'20px'} width={'20px'} />
                            </IconButton>
                          </HtmlTooltip>
                        </ClickAwayListener> */}
                    </div>
                  ) : (
                    <p
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                      }}
                    >
                      {'_'}
                    </p>
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthDate, classes.p0]}
                >
                  {row.createdAt ? (
                    <div>
                      {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                    </div>
                  ) : (
                    <p
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                      }}
                    >
                      {'_'}
                    </p>
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatisticBtn, classes.p0]}
                >
                  {row.totalCus ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {row.totalCus}
                      </p>
                      {/* <IconButton
                              size="small"
                              style={{ marginLeft: '14px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setScenarioCustomerSelected(row.id)
                              }}
                            >
                              <PeopleOutline height={'20px'} width={'20px'} />
                            </IconButton> */}
                    </div>
                  ) : (
                    <p
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                      }}
                    >
                      {'_'}
                    </p>
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.customerSuccess}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.customerFailed}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.customerNotSend}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.totalMes}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.messageSuccess}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.messageFailed}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0]}
                >
                  <div>{row.messageNotSend}</div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatus, classes.p0]}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CampaignStatusLabel
                      size="small"
                      type={row?.status}
                      message={campaignStatusDir[row?.status]}
                    />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthStatus, classes.p0]}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TimeLabel
                      sendTime={
                        row?.schedule?.scheduleTime
                          ? parseInt(row?.schedule?.scheduleTime)
                          : null
                      }
                    />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  className={[classes.widthActions, classes.p0]}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: '0.2px solid rgb(246, 239, 239)',
                    boxShadow: 'none',
                    backgroundColor: '#fafafa',
                    position: 'sticky',
                    right: 0,
                    top: 0,
                    zIndex: 2,
                    height: '52px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '8px 0',
                    }}
                  >
                    <Tooltip title="See Detail">
                      <Button
                        style={{
                          background: 'none',
                          padding: 0,
                        }}
                        onClick={() => handleOpenScenarioDetail(row.id)}
                      >
                        <div className={classes.actionBtnDetail}>
                          <p className={classes.actionTitle}>Detail</p>
                        </div>
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Flex className={classes.paginationBottom}>
          <Pagination
            style={{
              marginRight: '20px',
            }}
            count={Math.ceil(
              scenarioList?.totalElements / defaultSizeGetScenario
            )}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            disabled={scenarioList?.totalElements === 0}
            color="secondary"
          />
        </Flex>
      </div>
    </div>
  )
}

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`

export default ScenarioList
