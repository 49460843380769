import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ScenarioView from './ScenarioView'
import * as actionsFanpage from '../Fanpage/FanpageActions'
import * as actionsCustomer from './ScenarioActions'
import * as selectorsFanpage from '../Fanpage/FanpageSelectors'
import * as selectorsCustomer from './ScenarioSelectors'
import * as selectorsApp from '../App/AppSelectors'
const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScenarioView)
)
