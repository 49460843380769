import axiosClient from "./axiosClient";
import urlApi from "./url";

const tagApi = {
  get: (params) => {
    return axiosClient.get(`${urlApi.tagApi.tag}`, { params });
  }
};

export default tagApi;
