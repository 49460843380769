import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { PeopleAltOutlined } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from '@material-ui/lab'
import cn from 'classnames'
import Flex from 'components/Flex'
import TagPreviewItem from 'components/TagPreviewItem'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectFanpageSelected } from 'views/Customer/CustomerSelectors'
import fanpageApi from '../../api/fanpageApi'
import { showError, showSuccess } from '../../stores/alert/slice.alert'
import Confirm from '../Modals/Confirm'
import RenameTagModal from '../RenameTagModal'
import CustomersInTagModal from '../CustomersInTagModal/CustomersInBomBotTagModal'
import CustomersInBomBotTagModal from '../CustomersInTagModal/CustomersInBomBotTagModal'

const useStyles = makeStyles({
  widthCheckBox: {
    minWidth: '60px',
    width: '4%',
  },
  widthSeq: {
    minWidth: '80px',
    padding: '0 8px !important',
  },
  widthId: {
    minWidth: '146px',
    padding: '0 8px !important',
  },
  widthName: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthCreatedAt: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthCreatedBy: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthQuantityCus: {
    minWidth: '120px',
    padding: '0 8px !important',
  },
  widthActions: {
    minWidth: '140px',
    width: '6%',
    padding: '0 8px !important',
  },
  p0: {
    padding: '0',
    margin: '0',
  },
  heightHeader: {
    height: '52px',
  },
  f600: {
    fontWeight: 600,
  },
  checkBoxPadding: {
    padding: '10px 20px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  closeBtn: {
    color: '#ffffff',
    backgroundColor: '#dc3545',
    border: '2px solid #dc3545',
    padding: '6px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c82333',
      borderColor: '#bd2130',
    },
  },
  submitBtn: {
    color: 'white',
    backgroundColor: '#28a745',
    border: '2px solid #28a745',
    padding: '6px 26px',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#218838',
      borderColor: '#1e7e34',
    },
  },
  numCusInp: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 26px',
    margin: '12px 0',
    minHeight: '48px',
  },
  container: {
    flex: 1,
    width: '100%',
  },
  paginationBottom: {
    display: 'flex !important',
    justifyContent: 'end !important',
    alignItems: 'center !important',
  },
  buttonLoadMore: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: '#fff',
    border: '1px solid #dbdbdb',
    borderRadius: '.375em',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#363636',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: `BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    fontSize: '0.8rem',
    height: '1.8em',
    justifyContent: 'center',
    lineHeight: '1.5',
    padding: 'calc(.5em - 1px) 1em',
    position: 'relative',
    textAlign: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    touchAction: 'manipulation',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: '#b5b5b5',
    },
    '&:active': {
      borderColor: '#4a4a4a',
      outline: '0',
    },
    '&:focus': {
      borderColor: '#485fc7',
      outline: '0',
    },
    '&:focus:not(:active)': {
      boxShadow: 'rgba(72, 95, 199, 0.25) 0 0 0 0.125em',
    },
  },
  rowItem: {
    cursor: 'pointer',
  },

  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#637381',
    padding: '8px',
    marginRight: '8px',
    '&:hover': {
      color: '#212B36',
      backgroundColor: 'rgba(99, 115, 129, 0.08)',
      borderRadius: '50%',
    },
  },
  dialogTitle: {
    position: 'relative',
    paddingRight: '48px',
    margin: 0,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

const countRowPerPages = 10

export const tagType = {
  BOMBOT_TAGS: 'BOMBOT_TAGS',
  FACEBOOK_TAGS: 'FACEBOOK_TAGS',
}

const BombotTagsManagement = ({
  getData,
  getFanpage,
  tabVal,
  setIsLoading,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const pageSelected = useSelector(selectFanpageSelected)
  const [page, setPage] = useState(0)
  const [bombotTagData, setBombotTagData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [renameSelected, setRenameSelected] = useState(null)
  const [checkedList, setCheckedList] = useState({})
  const [selectedTag, setSelectedTag] = useState(null)

  const dispatch = useDispatch()

  const getBombotTags = async (page) => {
    if (!pageSelected.id) return

    let search = null

    if (searchVal.trim()) {
      search = `id|name==${searchVal}`
    }

    let params = {
      id: pageSelected.id,
      page: page,
      size: countRowPerPages,
    }

    if (!_.isNull(search)) {
      params = {
        id: pageSelected.id,
        page: page,
        size: countRowPerPages,
        search,
      }
    }

    return fanpageApi
      .getBomBotTagList(pageSelected?.id, params)
      .then((res) => setBombotTagData(res))
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)

        if (tabVal === tagType.BOMBOT_TAGS) {
          await getBombotTags(page)
        }
      } catch (error) {
        dispatch(showError('Co loi xay ra!'))
      } finally {
        setIsLoading(false)
      }
    })()
  }, [pageSelected, page, tabVal])

  const handleSearch = async () => {
    setIsLoading(true)
    try {
      setPage(0)

      if (tabVal === tagType.BOMBOT_TAGS) {
        await getBombotTags(0)
      }
    } catch (error) {
      dispatch(showError('Co loi xay ra!'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleCheckAllTag = (isChecked) => {
    const currentPageTags = bombotTagData?.content
    let checkListTmp = { ...checkedList }
    currentPageTags.forEach((tag) => {
      checkListTmp[tag.id] = isChecked
    })
    setCheckedList(checkListTmp)
  }

  const handleCheckTag = (isChecked, tagId) => {
    setCheckedList((prev) => ({
      ...prev,
      [tagId]: isChecked,
    }))
  }

  const handleDeleteSearch = () => {
    setSearchVal('')
    setPage(0)
  }

  const handleSubmitDeleteTags = () => {
    if (Object.keys(checkedList).length === 0) {
      dispatch(showError('Vui lòng chọn ít nhất một tag'))
      return
    }
    setOpenConfirmDelete(true)
  }

  const handleDeleteTags = async () => {
    setIsLoading(true)
    try {
      await fanpageApi.deleteBomBotTagList(pageSelected?.id, {
        tagId: Object.keys(checkedList),
      })

      getFanpage()
      getData()

      await getBombotTags()

      setOpenConfirmDelete(false)
      dispatch(
        showSuccess(`Đã xóa ${Object.keys(checkedList).length} tag thành công`)
      )
      setCheckedList({})
    } catch (error) {
      dispatch(showError('Xóa tag thất bại. Vui lòng thử lại sau'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleRenameTag = async (renameTag, newName) => {
    try {
      const payload = {
        name: newName,
      }

      await fanpageApi.renameTag(pageSelected.id, renameTag.id, payload)

      getFanpage()
      getData()

      await getBombotTags()
    } catch (error) {
      console.log(error)
    } finally {
      setRenameSelected(null)
    }
  }

  return (
    <>
      <Flex align="center" auto>
        <TextField
          variant="outlined"
          size="small"
          placeholder={t('tag_management.search')}
          className="w-100 bg-white"
          id="input-with-icon-textfield"
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#637381' }} />
              </InputAdornment>
            ),
            endAdornment: searchVal && (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleDeleteSearch}
                  size="small"
                  style={{
                    padding: 4,
                    marginRight: -8,
                  }}
                >
                  <X size={16} style={{ color: '#637381', marginRight: 4 }} />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              paddingRight: 8,
            },
          }}
          style={{
            width: '100%',
            marginRight: '10px', // Thêm margin để tạo khoảng cách với button
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleSearch}
          style={{
            backgroundColor: '#00AB55',
            color: 'white',
            textTransform: 'none',
            minWidth: '100px',
            height: '40px',
            marginRight: '0 !important',
          }}
        >
          {t('common.btn.search')}
        </Button>
      </Flex>

      <TableContainer
        component={Paper}
        style={{
          boxShadow: 'none',
          height: '390px',
          marginTop: '20px',
          width: '720px',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={[classes.p0, classes.widthCheckBox]}>
                <FormControlLabel
                  className={[classes.p0, classes.checkBoxPadding]}
                  control={
                    <Checkbox
                      className={classes.p0}
                      checked={bombotTagData?.content?.every(
                        (tag) => checkedList[tag.id]
                      )}
                      onChange={(_, isChecked) => {
                        handleCheckAllTag(isChecked)
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell
                align="left"
                className={[classes.widthSeq, classes.p0, classes.f600]}
              >
                {t('tag_management.list.tag_ord_nos')}
              </TableCell>
              <TableCell
                align="left"
                className={[classes.widthId, classes.p0, classes.f600]}
              >
                {t('tag_management.list.tag_id')}
              </TableCell>
              <TableCell
                align="left"
                className={[classes.widthName, classes.p0, classes.f600]}
              >
                {t('tag_management.list.tag_name')}
              </TableCell>
              {/* <TableCell
                      align="left"
                      className={[
                        classes.widthCreatedAt,
                        classes.p0,
                        classes.f600,
                      ]}
                    >
                      {t('tag_management.list.date_created')}
                    </TableCell> */}
              {/* <TableCell
                      align="left"
                      className={[
                        classes.widthCreatedBy,
                        classes.p0,
                        classes.f600,
                      ]}
                    >
                      {t('tag_management.list.creator')}
                    </TableCell> */}
              {/* <TableCell
                      align="left"
                      className={[
                        classes.widthQuantityCus,
                        classes.p0,
                        classes.f600,
                      ]}
                    >
                      {t('tag_management.list.num_customer')}
                    </TableCell> */}
              <CellSticky
                align="left"
                className={[classes.widthActions, classes.pRow, classes.f600]}
                style={{
                  borderLeft: '0.2px solid rgb(246, 239, 239)',
                  boxShadow: 'none',
                  backgroundColor: '#fafafa',
                }}
              >
                {t('tag_management.action')}
              </CellSticky>
            </TableRow>
          </TableHead>
          <TableBody>
            {bombotTagData?.content?.length > 0 &&
              bombotTagData?.content?.map((tag, index) => (
                <TableRow
                  key={index}
                  className={[classes.heightHeader, classes.rowItem]}
                >
                  <TableCell
                    align="left"
                    className={[classes.p0, classes.widthCheckBox]}
                  >
                    <FormControlLabel
                      className={[classes.p0, classes.checkBoxPadding]}
                      control={
                        <Checkbox
                          className={classes.p0}
                          checked={checkedList[tag.id] || false}
                          onChange={(_, isChecked) => {
                            handleCheckTag(isChecked, tag.id)
                          }}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={[classes.widthSeq, classes.p0]}
                  >
                    {bombotTagData?.number * countRowPerPages + index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={[classes.widthId, classes.p0]}
                  >
                    {tag?.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={[classes.widthName, classes.p0]}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Tooltip title={tag?.name}>
                        <div>
                          <TagPreviewItem
                            key={index}
                            styleText={{
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              textAlign: 'center',
                              display: 'block',
                            }}
                            style={{
                              display: 'inline-block',
                            }}
                            background={
                              tag?.backgroundColor || `#${tag?.color}`
                            }
                            color={tag?.textColor || '#FFF'}
                            name={tag?.name}
                            size="small"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  {/* <TableCell
                          align="left"
                          className={[classes.widthCreatedAt, classes.p0]}
                        >
                          {tag.createdAt
                            ? moment(tag.createdAt).format('DD/MM/YYYY HH:mm')
                            : 'N/A'}
                        </TableCell> */}
                  {/* <TableCell
                          align="left"
                          className={[classes.widthCreatedBy, classes.p0]}
                        >
                          {tag.createdByUserId || 'N/A'}
                        </TableCell> */}
                  {/* <TableCell
                          align="left"
                          className={[classes.widthQuantityCus, classes.p0]}
                        >
                          {tag.totalCus || 'N/A'}
                        </TableCell> */}
                  <CellSticky
                    align="left"
                    className={[classes.widthActions, classes.pRow]}
                    style={{
                      borderLeft: '0.2px solid rgb(246, 239, 239)',
                      boxShadow: 'none',
                      zIndex: 0,
                    }}
                  >
                    <Flex align="center" justify="center">
                      <Tooltip title="Chi tiết">
                        <span
                          className={classes.icon}
                          onClick={() => {
                            setSelectedTag(tag.id)
                          }}
                        >
                          <PeopleAltOutlined />
                        </span>
                      </Tooltip>
                      <Tooltip title="Chỉnh sửa">
                        <span
                          className={cn(classes.icon, 'ml-2')}
                          onClick={() => {
                            setRenameSelected(tag)
                          }}
                        >
                          <EditIcon />
                        </span>
                      </Tooltip>
                    </Flex>
                  </CellSticky>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {bombotTagData?.content?.length === 0 && (
          <p className={classes.noData}>{t('update.no_data')}</p>
        )}
      </TableContainer>

      <DialogActions className={classes.actions}>
        <Pagination
          style={{ position: 'unset' }}
          count={bombotTagData.totalPages + 1}
          page={page + 1}
          onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          color="secondary"
        />
        <Flex align="center" justify="flex-end">
          <Button
            size="small"
            type="submit"
            className="btn--rounder btn--primary--filled"
            onClick={handleSubmitDeleteTags}
          >
            <Flex align="center">
              <p>{t('common.btn.delete')}</p>
            </Flex>
          </Button>
        </Flex>
      </DialogActions>

      <Confirm
        open={openConfirmDelete}
        title="Xác nhận xóa tag"
        content={`Bạn có chắc chắn muốn xóa ${
          Object.keys(checkedList).length
        } tag đã chọn?`}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={handleDeleteTags}
        confirmText="Xóa"
        cancelText="Hủy"
      />

      {!_.isNull(selectedTag) && (
        <CustomersInBomBotTagModal
          isOpen={!_.isNull(selectedTag)}
          handleClose={() => setSelectedTag(null)}
          tagId={selectedTag}
        />
      )}

      {renameSelected && (
        <RenameTagModal
          open={!_.isNull(renameSelected)}
          handleClose={() => setRenameSelected(null)}
          handleSubmit={handleRenameTag}
          renameSelected={renameSelected}
        />
      )}
    </>
  )
}

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: -1px;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`

export default BombotTagsManagement
