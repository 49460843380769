import { Button } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Autocomplete from '@material-ui/lab/Autocomplete'
import cn from 'classnames'
import Flex from 'components/Flex'
import _ from 'lodash'
import MuiPhoneInput from 'material-ui-phone-number'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Tour from 'reactour'
import {
  selectCountries,
  selectCountriesOnly,
  selectGuideListByKey,
} from 'stores/config/config.selector'
import {
  getIsGuideByKey,
  GUIDE_TYPE_ENUM,
  handleUpgrade,
  setIsGuideByKey,
} from 'utils/helper'
import ButtonComponent from '../../components/Button'
import FormDialog from '../../components/FormDialog'
import ListCampaignComponent from '../../components/ListCampaign'
import Loading from '../../components/Loading'
import types from '../../constants/types'
import MainLayout from '../MainLayout'
import AccountInfo from './items/AccountInfo'
import ChartDashboard from './items/Chart'
import DashboardChart from './items/Chart/Dashboard'
import DialogAlertPoints from './items/DialogAlertPoints'
import DialogVerifyPhone from './items/DialogVerifyPhone'
import Introduce from './items/Introduce'
import MessengerButton from './items/MessengerButton'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'red',
  },
  sidebar: {
    width: 250,
    float: 'left',
    height: '100vh',
    overflowY: 'auto',
    boxShadow:
      '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
    background: 'white',
  },
  contentRight: {
    width: 'calc(100vw - 265px)',
    float: 'right',
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    background: '#f0f0f0',
    padding: 15,
  },
  userName: {
    fontWeight: 'bold',
  },
  right: {
    marginLeft: 15,
  },
  wrapAccount: {
    padding: 15,
  },
  form: {
    width: '100%',
    // "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
    //   transform: "translate(0px, -20px) scale(0.9) !important",
    //   overflow: "hidden !important",
    //   "-moz-box-sizing": "border-box !important",
    //   "-webkit-box-orient": "vertical !important",
    //   "-webkit-box-sizing": "border-box !important",
    //   "-webkit-line-clamp": "1 !important",
    //   display: "-webkit-box !important",
    //   /* padding: 0px 20px; */
    // },
    // "& .MuiInputLabel-filled": {
    //   transform: "translate(0px, -20px) scale(0.9) !important",
    //   overflow: "hidden !important",
    //   "-moz-box-sizing": "border-box !important",
    //   "-webkit-box-orient": "vertical !important",
    //   "-webkit-box-sizing": "border-box !important",
    //   "-webkit-line-clamp": "1 !important",
    //   display: "-webkit-box !important",
    //   /* padding: 0px 20px; */
    // },
    // "& .MuiFilledInput-input": {
    //   padding: "10px 12px 10px 2px",
    // },
    // "& .MuiAutocomplete-root": {
    //   marginTop: "30px !important",
    //   marginBottom: "30px !important",
    // },
  },
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 0,
  },
  buttonVerify: {
    width: 300,
    margin: '5px auto',
  },
  textField: {
    margin: '30px 0px 5px 0px !important',
  },
  select: {
    margin: '10px 0px !important',
  },
  formVerify: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
    '& $textField': {
      width: 330,
    },
    '& button': {
      margin: '0px 0px 0px 15px',
    },
  },
  number: {
    fontWeight: 'bold',
  },
  wrapSelectLabel: {
    zIndex: 1,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: -5,
    '&.MuiFormLabel-filled': {
      display: 'none !important',
    },
    '&.Mui-focused': {
      display: 'none !important',
    },
  },
  wrapperMain: {
    paddingRight: '0',
  },
  wrapperBoxInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px 15px 0px',
  },

  boxInfoCampaign: {
    background: '#FF5757',
    '& h3': {
      background: '#FF7979',
    },
    '& $link': {
      background: '#E64F4F',
      lineHeight: 0.8,
    },
  },

  wrapperTable: {
    width: '100%',
    paddingRight: 0,
  },
  error: {
    margin: '0px',
    fontSize: '13px',
    color: 'red',
    marginTop: '4px',
    marginLeft: '4px',
  },
  errorCode: {
    margin: '10px 0px 0px 10px',
    fontSize: '14px',
    color: 'red',
    textAlign: 'center',
  },
  wrapAutocomplete: {
    marginTop: 10,
    '& .MuiAutocomplete-inputRoot': {
      height: 'auto',
    },
  },
}))

const HomeView = (props) => {
  const {
    getAccount,
    onVerifyNumberPhone,
    onGetOtp,
    verifyNumberPhone,
    user,
    onWhatYouNeed,
    onDashboard,
    share,
    dashboard,
    getCampaign,
    campaign,
    verifyNumberPhoneError,
    onVerifyNumberPhoneVoice,
    updateUser,
    loading,
  } = props
  console.log('🚀 ~ HomeView ~  campaign:', campaign)
  console.log('🚀 ~ HomeView ~ dashboard:', dashboard)
  const classes = useStyles()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [business, setBusiness] = useState('')
  const [country, setCountry] = useState()
  const [giftPoints, setGiftPoints] = useState(0)
  const [isAlertPoints, setIsAlertPoints] = useState(true)

  const [province, setProvince] = useState('')
  const [isInfo, setIsInfo] = useState(true) // true
  const [isVerify, setIsVerify] = useState(false)
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [countryError, setCountryError] = useState('')
  // const [proviceError, setProviceError] = useState("");
  const [isVisibleIntroduce, setIsVisibleIntroduce] = useState(true)
  const [uid, setUID] = useState('')
  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.overview)
  )
  const [tourConfig, setTourConfig] = useState(null)
  const { t, i18n } = useTranslation()

  const countriesOnly = useSelector(selectCountriesOnly)
  const countries = useSelector(selectCountries)
  const dispatch = useDispatch()
  const isNavigateUpdateRef = useRef()

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split('T')[0]
  )
  const [dailyData, setDailyData] = useState({})

  useEffect(() => {
    // Tính toán dữ liệu cho ngày đã chọn
    const dateData = dashboard.dailyData?.find(
      (item) => item.date === selectedDate
    )
    if (dateData) {
      setDailyData(dateData)
    } else {
      // Nếu không có dữ liệu cho ngày đã chọn, có thể đặt giá trị mặc định
      setDailyData({
        totalCus: 0,
        totalSent: 0,
        totalSuccess: 0,
        totalFail: 0,
      })
    }
  }, [selectedDate, dashboard])

  useEffect(() => {
    checkAlert()
    if (verifyNumberPhone) {
      setIsInfo(false)
      setIsVerify(false)
    }
  }, [verifyNumberPhone])
  /* End Off veriry phone */

  useEffect(() => {
    if (user?.id) {
      const url_string = window.location.href //window.location.href
      const url = new URL(url_string)
      const ref = url.searchParams.get('ref')
      const event = url.searchParams.get('event')

      if (
        event == 'upgrade' &&
        ref == 'extension' &&
        !isNavigateUpdateRef.current
      ) {
        isNavigateUpdateRef.current = true
        handleUpgrade({ i18n, user, dispatch })
      }
    }
  }, [user])

  useEffect(() => {
    onWhatYouNeed()
    onDashboard()
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      sort: 'createdAt,desc',
    }
    getCampaign(params)
  }, [])

  /* not veriry phone */
  useEffect(() => {
    if (user) {
      if (user?.name || getAccount?.name) {
        setName(user.name || getAccount.name)
      }
      if (user?.email || getAccount?.email) {
        setEmail(user.email || getAccount.email)
      }
      if (user?.phone) {
        let phone = user?.phone || getAccount?.phone
        // tạm thoi
        if (/^0/g.test(user?.phone)) {
          phone = user?.phone?.replace(/^0/g, '+84')
        }
        setPhone(phone)
      }
      // setBusiness(user?.business);
      setCompany(user?.company || '')

      setUID(user?.id)
      if (user.phone && user.verify) {
        setIsInfo(false)
        setIsVerify(false)
        if (
          !isVisibleIntroduce &&
          getIsGuideByKey(GUIDE_TYPE_ENUM.overview) &&
          tourOverview &&
          tourOverview?.length
        ) {
          setTourConfig(tourOverview)
          setIsGuideByKey(GUIDE_TYPE_ENUM.overview)
        }
      }
    }
  }, [user, tourOverview, isVisibleIntroduce])

  useEffect(() => {
    if (user?.business && share.business && !business) {
      let business = _.toString(user.business).split(',')
      const data = business?.map((b) => {
        return share.business.find((bus) => bus.id === b)
      })
      setBusiness(data)
    }

    if (countries && user.country) {
      setCountry(user.country)
      setProvince(user?.province)
    }

    if (share?.package && share?.package?.giftPoint) {
      setGiftPoints(share.package.giftPoint)
    }
  }, [user, share, countries])

  function checkAlert() {
    if (localStorage.getItem('alertGiftBox') != null) {
      setIsAlertPoints(false)
    } else {
      setIsAlertPoints(true)
    }
  }

  const handleChange = (key, value) => {
    switch (key) {
      case 'name':
        setName(value)
        break
      case 'phone':
        setPhone(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'company':
        setCompany(value)
        break
      case 'province':
        setProvince(value)
        break
      case 'business':
        setBusiness(value)
        break
      case 'country':
        setCountry(value)
        setProvince('')
        break

      default:
        break
    }
  }

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value)
  }

  const providerOptions = useMemo(() => {
    if (country) {
      const countrySelected = countries.find((c) => c.countryName === country)
      if (countrySelected) return countrySelected?.provinces
    }
  }, [country])

  const handleVerify = (otp) => {
    const data = {
      name,
      phone,
      email,
      company,
      business: business ? business?.map((v) => v?.id) : [],
      country,
      province,
    }
    onVerifyNumberPhone({ phone: phone, otp }, data, uid)
    if (verifyNumberPhone) {
      setIsInfo(false)
    }
  }

  const handleVerifyOtpVoice = (otp) => {
    console.warn(business)
    const data = {
      name,
      phone,
      email,
      company,
      business: business ? business.map((v) => v.id) : [],
      country,
      province,
    }
    onVerifyNumberPhoneVoice({ phone: phone, otp }, data, uid)
    if (verifyNumberPhone) {
      setIsInfo(false)
    }
  }

  const handleSubmit = () => {
    if (validate()) {
      // onGetOtp({ phone: phone, type: "text" });
      setIsInfo(false)
      setIsVerify(true)
    }
  }

  const validate = () => {
    setNameError('')
    setPhoneError('')
    setEmailError('')
    let isValid = true
    if (!name || !/\S/.test(name)) {
      isValid = false
      setNameError(t('noti.name_incorrect'))
    }
    if (!email) {
      isValid = false
      setEmailError(t('noti.email'))
    }

    if (email) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
      if (!pattern.test(email)) {
        isValid = false
        setEmailError(t('noti.email_incorrect'))
      }
    }

    if (!phone) {
      isValid = false
      setPhoneError(t('noti.phone'))
    }
    if (!phone?.trim() || phone.length < 5 || phone.length > 20) {
      isValid = false
      setPhoneError(t('noti.phone_incorrect'))
    }
    if (!country) {
      isValid = false
      setCountryError(t('update.country_incorrect'))
    } else {
      setCountryError('')
    }
    // if (country && !province) {
    //   isValid = false;
    //   setProviceError(t("update.province_incorrect"));
    // } else {
    //   setCountryError("");
    // }
    return isValid
  }

  const handleBackVerify = () => {
    setIsInfo(true)
    setIsVerify(false)
  }

  const closeModalIntroduce = () => {
    setIsVisibleIntroduce(false)
    setTourConfig()
  }

  const closeAlertPoints = () => {
    setIsAlertPoints(false)
  }

  const closeTour = () => {
    setTourConfig(null)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  return (
    <MainLayout>
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        accentColor="#05ae34"
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        onRequestClose={closeTour}
      />
      {loading && <Loading />}
      <FormDialog
        transitionDelay={true}
        isOpen={isInfo}
        // isOpen={true}
        // handleClose={() => handleCloseOpen("info")}
      >
        <div className={classes.wrapAccount}>
          <div className={classes.account}>
            <div className={classes.left}>
              {user.avatar ? (
                <Avatar alt={user.name} src={user.avatar} />
              ) : (
                <Avatar alt={user.name} />
              )}
            </div>
            <div className={classes.right}>
              <p>
                {t('form_collect_info.title')}{' '}
                <span className={classes.userName}>{user.name}!</span>
              </p>{' '}
              <p>{t('form_collect_info.description')}.</p>
            </div>
          </div>
          <p className="mt-3">{t('form_collect_info.helper')}</p>
          <p className="w-100">{t('form_collect_info.helper_2')}</p>
          <FormControl className={classes.form}>
            <div className="my-3 w-100">
              <TextField
                variant="outlined"
                size="small"
                error={false}
                className="w-100"
                label={t('form_collect_info.fullname') + ' (*)'}
                value={name}
                onChange={(event) => handleChange('name', event.target.value)}
              />
              {!_.isEmpty(nameError) && (
                <p className={classes.error}>{nameError}</p>
              )}
            </div>
            <div className="mb-3 w-100">
              <MuiPhoneInput
                defaultCountry={'vn'}
                variant="outlined"
                className="w-100"
                error={false}
                label={t('form_collect_info.phone') + ' (*)'}
                size="small"
                value={phone}
                type="text"
                onChange={(phone) => handleChange('phone', phone)}
              />
              {/* <TextField
                className="w-100"
                error={false}
                label={t("form_collect_info.phone") + " (*)"}
                variant="outlined"
                size="small"
                value={phone}
                type="text"
                onChange={(event) => handleChange("phone", event.target.value)}
              /> */}
              {!_.isEmpty(phoneError) && (
                <p className={classes.error}>{phoneError}</p>
              )}
            </div>
            <div className="mb-3 w-100">
              <TextField
                error={false}
                label={t('form_collect_info.email') + ' (*)'}
                className="w-100"
                size="small"
                value={email}
                variant="outlined"
                onChange={(event) => handleChange('email', event.target.value)}
              />
              {!_.isEmpty(emailError) && (
                <p className={classes.error}>{emailError}</p>
              )}
            </div>
            <div className="mb-3 w-100">
              <Autocomplete
                className="w-100"
                id="business"
                multiple
                options={share?.business || []}
                disableCloseOnSelect
                value={business || []}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, value) => {
                  handleChange('business', value)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('form_collect_info.industry')}
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.name}
                  </>
                )}
              />
            </div>

            <div className="mb-3 w-100">
              <TextField
                className="w-100"
                error={false}
                label={t('form_collect_info.business_name')}
                defaultValue={company}
                variant="outlined"
                size="small"
                value={company}
                onChange={(event) =>
                  handleChange('company', event.target.value)
                }
              />
            </div>
            <div className="mb-3 w-100">
              <Autocomplete
                className="w-100"
                id="country"
                value={country || ''}
                options={countriesOnly || []}
                style={{ marginTop: 10 }}
                onChange={(e, value) => handleChange('country', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('form_collect_info.country') + ' (*)'}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
              {!_.isEmpty(countryError) && (
                <p className={classes.error}>{countryError}</p>
              )}
            </div>
            {country && country !== 'Other' && (
              <div className="mb-3 w-100">
                <Autocomplete
                  className="w-100"
                  id="province"
                  options={providerOptions || []}
                  getOptionLabel={(option) => option || ''}
                  style={{ marginTop: 10 }}
                  value={province || ''}
                  onChange={(e, value) => handleChange('province', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('form_collect_info.province')}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                {/* {!_.isEmpty(proviceError) && (
                  <p className={classes.error}>{proviceError}</p>
                )} */}
              </div>
            )}

            <Flex justify="center">
              <ButtonComponent
                className={cn(
                  'classes.button',
                  'btn--rounder',
                  'text-uppercase'
                )}
                title={t('common.btn.save')}
                backgroundColor="#05AE34"
                color="white"
                handleClickButton={() => handleSubmit()}
              />
            </Flex>
          </FormControl>
        </div>
      </FormDialog>
      {isVerify && (
        <DialogVerifyPhone
          onGetOtp={onGetOtp}
          phone={phone}
          handleBackVerify={handleBackVerify}
          handleVerify={handleVerify}
          verifyNumberPhoneError={verifyNumberPhoneError}
          handleVerifyOtpVoice={handleVerifyOtpVoice}
        />
      )}
      <div className={classes.wrapperMain}>
        <Flex className="my-4">
          <AccountInfo data={dashboard} />
          <ChartDashboard data={dashboard} />
        </Flex>

        <div className={classes.wrapperCampaignHistory}>
          <h3 className="pt-4">{t('overall.lastest_campaign.title')}</h3>
          <div className={classes.wrapperTable}>
            <ListCampaignComponent
              data={campaign}
              isPagination={false}
              isSearch={false}
            />
          </div>
        </div>

        <div className={classes.wrapperCampaignHistory}>
          <h3 className="pt-4">Biểu đồ phân tích</h3>
          <div className={classes.wrapperTable}>
            <DashboardChart
              dashboard={dashboard}
              campaigns={campaign}
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
            />
          </div>
        </div>
      </div>
      {isVisibleIntroduce && (
        <Introduce isVisibleIntroduce handleClose={closeModalIntroduce} />
      )}

      {isAlertPoints && giftPoints > 150 && (
        <DialogAlertPoints
          name={user?.name}
          isAlertPoints
          point={giftPoints}
          handleClose={closeAlertPoints}
        />
      )}
      <MessengerButton />
    </MainLayout>
  )
}

HomeView.propTypes = {}

HomeView.defaultProps = {}

export default HomeView
