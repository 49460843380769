import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { Button, MenuItem, Menu } from '@material-ui/core'
import TranslateIcon from '@material-ui/icons/Translate'
import Flex from 'components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from 'views/Home/HomeActions'
import { selectUser } from 'views/App/AppSelectors'

const ChangeLanguage = ({ isUser }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  const handleChangeLanguage = (lng) => {
    handleClose()
    i18n.changeLanguage(lng)
    if (isUser) {
      updateLngUser(lng)
    }
  }
  const currentLanguage = useMemo(() => {
    if (!i18n?.language) return 'Change language'
    switch (i18n.language?.substring(0, 2)) {
      case 'kh':
        return 'ភាសាខ្មែរ'
      case 'vi':
        return 'Tiếng Việt'
      case 'en':
        return 'English'
      default:
        return 'Change language'
    }
  }, [i18n.language])

  const updateLngUser = (lng) => {
    dispatch(
      updateUser(
        {
          ...user,
          lang: lng,
        },
        user.id
      )
    )
  }

  const onSelectLanguage = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const languages = [
    {
      label: 'Tiếng Việt',
      value: 'vi',
    },
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'ភាសាខ្មែរ',
      value: 'kh',
    },
  ]

  return (
    <>
      <Button className="language_select" onClick={onSelectLanguage}>
        <Flex align="center">
          <TranslateIcon className="mr-1" />
          {currentLanguage === 'Tiếng Việt' && (
            <img className="mr-1" src="/image/vn.png" alt="icon" />
          )}
          {currentLanguage === 'English' && (
            <img className="mr-1" src="/image/usa.png" alt="icon" />
          )}
          {currentLanguage === 'ភាសាខ្មែរ' && (
            <img className="mr-1" src="/image/cam.png" alt="icon" />
          )}
          {currentLanguage}
        </Flex>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((lng, index) => (
          <MenuItem key={index} onClick={() => handleChangeLanguage(lng.value)}>
            {lng.label === 'Tiếng Việt' && (
              <img className="mr-1" src="/image/vn.png" alt="icon" />
            )}
            {lng.label === 'English' && (
              <img className="mr-1" src="/image/usa.png" alt="icon" />
            )}
            {lng.label === 'ភាសាខ្មែរ' && (
              <img className="mr-1" src="/image/cam.png" alt="icon" />
            )}
            {lng.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ChangeLanguage
