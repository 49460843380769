import tagApi from "../../api/tagApi";
import { setLoading } from "../App/AppReducer";
import { setTag } from "./TagReducer";

export function getTag(params) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return tagApi.get(params).then(
      (res) => {
        dispatch(setTag(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}
