import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const tagReducer = createSlice({
  name: "tag",
  initialState: initialState.tagReducer,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    }
  }
});

export const { setTag } = tagReducer.actions;

export default tagReducer.reducer;
