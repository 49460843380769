import React, { useEffect, useMemo, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card, CardContent, TextField } from '@material-ui/core'
import { Bar, Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

const FanpageChart = ({ style }) => {
  const [data, setData] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      try {
        const res = await homeApi.getFanpageStatistic({
          page: 0,
          size: 50,
          sort: 'desc',
        })
        console.log('res ==>', res)
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [selectedDate])

  const chartCustomerPerFanpages = useMemo(() => {
    return {
      labels: data?.content?.map((item) => item.name), // Directly using fanpage names
      datasets: [
        {
          label: t('dashboard.customer_count'),
          data: data?.content?.map((item) => item.totalCus), // Directly using customer counts
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }, [data])

  const barOptions = {
    indexAxis: 'y', // This makes the bar chart horizontal
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  }

  return (
    <Card style={style}>
      <h3
        style={{
          margin: 0,
        }}
      >
        {t('dashboard.customer')}
      </h3>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Bar data={chartCustomerPerFanpages} options={barOptions} />
      </div>
    </Card>
  )
}

export default FanpageChart
