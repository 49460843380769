import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TagView from './TagView'
import * as actionsTag from './TagActions'

import * as selectorsFanpage from '../Fanpage/FanpageSelectors'
import * as selectorsCustomer from '../Customer/CustomerSelectors'
import * as actionsCustomer from '../Customer/CustomerActions'
import * as actionsFanpage from '../Fanpage/FanpageActions'

const mapStateToProps = (state) => {
  return {
    fanpageFull: selectorsFanpage.fanpageFull(state),
    fanpageSelected: selectorsCustomer.fanpageSelected(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTag: (params) => dispatch(actionsTag.getTag(params)),
  onFanpageSelected: (params) =>
    dispatch(actionsCustomer.fanpageSelected(params)),
  getFanpage: (params) => dispatch(actionsFanpage.getFanpageFull(params)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagView))
