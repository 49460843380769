import axios from 'axios'
import queryString from 'query-string'
import AppConfigAPI from './appConfig'
import i18n from 'i18next'

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: localStorage.getItem('token')
    ? {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token') || undefined,
      }
    : {
        'content-type': 'application/json',
      },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  if (i18n.language) {
    config.headers.lang = i18n.language?.substring(0, 2)
  }

  if (localStorage.getItem('token')) {
    config.headers.authorization = localStorage.getItem('token')
  }
  return config
})

let retry = 0

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }
    retry = 0
    return response
  },
  async (error) => {
    const payload = {
      errorCode: error?.response?.status || 0,
      message: 'webapp',
    }
    if (error.response?.data?.error?.message) {
      error.message = error.response.data.error.message
      payload.debug = error.response.data.error.message
    } else {
      payload.debug = error.message
    }
    if (error && error.response && error.response.status === 401) {
      console.log('error 401', error)
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      window.location.reload(true)
    }
    if (retry === 0) AppConfigAPI.recordLog([payload])
    retry++
    // Handle errors
    throw error
  }
)

export default axiosClient
