import React, { useEffect, useMemo, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card, CardContent, TextField } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import BaseDatePicker2 from '../../../../components/Form/BaseDatePicker2'
import { isEmpty } from 'lodash'
import Flex from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'

const GenAIChart = ({ style }) => {
  const [data, setData] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: [new Date(), new Date()],
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        const date = watch('date')

        const res = await homeApi.getGenAIStatistic({
          from: moment(date[0]).format('YYYY-MM-DD'),
          to: moment(date[1]).format('YYYY-MM-DD'),
        })
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [watch('date')])

  const dataCustomers = useMemo(() => {
    return {
      labels: [
        `${t('dashboard.created_content')}: ${data?.totalCreatedMessages}`,
        `${t('dashboard.customized_content')}: ${data?.totalFormattedMessages}`,
        `${t('dashboard.selected_content')}: ${data?.totalSelectedMessages}`,
      ],
      datasets: [
        {
          data: [
            data?.totalCreatedMessages || 0,
            data?.totalFormattedMessages || 0,
            data?.totalSelectedMessages || 0,
          ],
          backgroundColor: ['#ffc658', '#8884d8', '#AE1607'],
          hoverBackgroundColor: ['#e6b84d', '#7a6fb8', '#9e1410'],
        },
      ],
    }
  }, [data])

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  }

  const isEmpty = useMemo(() => {
    let sum = 0
    for (const property in data) {
      sum += data[property]
    }

    return sum === 0 ? true : false
  }, [data])

  return (
    <Card style={style}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '16px',
          height: '52px',
        }}
      >
        <h3 style={{ margin: 0 }}>Gen AI</h3>

        {/* <BaseDatePicker2
          name={'date'}
          control={control}
          defaultValue={[null, null]}
        /> */}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: '150px', width: '100%' }}
          >
            <p>{t('dashboard.no_data')}</p>
          </Flex>
        ) : (
          <Doughnut data={dataCustomers} options={doughnutOptions} />
        )}
      </div>
    </Card>
  )
}

export default GenAIChart
