import React, { useEffect, useMemo, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card, CardContent, TextField } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import BaseDatePicker2 from '../../../../components/Form/BaseDatePicker2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Flex from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'

const MessageChart = ({ style }) => {
  const [data, setData] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)

  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: [new Date(), new Date()],
    },
  })

  useEffect(() => {
    ;(async () => {
      const date = watch('date')

      try {
        const res = await homeApi.getMessageStatistic({
          from: moment(date[0]).format('YYYY-MM-DD'),
          to: moment(date[1]).format('YYYY-MM-DD'),
        })
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [watch('date')])

  const dataMessages = useMemo(() => {
    return {
      labels: [
        `${t('dashboard.failed_message')}: ${data?.totalMessagesFail}`,
        `${t('dashboard.message_not_sent')}: ${data?.totalMessagesNotSend}`,
        `${t('dashboard.successful_message')}: ${data?.totalMessagesSuccess}`,
      ],
      datasets: [
        {
          data: [
            data?.totalMessagesFail || 0,
            data?.totalMessagesNotSend || 0,
            data?.totalMessagesSuccess || 0,
          ],
          backgroundColor: ['#ffc658', '#8884d8', '#AE1607'],
          hoverBackgroundColor: ['#e6b84d', '#7a6fb8', '#9e1410'],
        },
      ],
    }
  }, [data])

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  }

  const isEmpty = useMemo(() => {
    let sum = 0
    for (const property in data) {
      sum += data[property]
    }

    return sum === 0 ? true : false
  }, [data])

  return (
    <Card style={style}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '16px',
          height: '52px',
        }}
      >
        <h3>{t('dashboard.message')}</h3>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BaseDatePicker2
            name={'date'}
            control={control}
            defaultValue={[null, null]}
          />

          <p
            style={{
              fontSize: '12px',
              fontStyle: 'italic',
              marginTop: '4px',
              color: '#8f8f8f',
            }}
          >
            {t('dashboard.message_created_from_to')}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: '150px', width: '100%' }}
          >
            <p>{t('dashboard.no_data')}</p>
          </Flex>
        ) : (
          <Doughnut data={dataMessages} options={doughnutOptions} />
        )}
      </div>
    </Card>
  )
}

export default MessageChart
