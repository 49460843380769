import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tab,
  TableCell,
  Tabs,
  TextField,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import LoadingWrapper from '../../components/LoadingWrapper'
import BombotTagsManagement from '../../components/TagsManagementModal/BombotTagsManagement'
import FbTagsManagement from '../../components/TagsManagementModal/FbTagsManagement'
import MainLayout from '../MainLayout'
import { Autocomplete } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import { getFanpageDetail } from '../Fanpage/FanpageActions'

const useStyles = makeStyles({
  widthCheckBox: {
    minWidth: '60px',
    width: '4%',
  },
  widthSeq: {
    minWidth: '80px',
    padding: '0 8px !important',
  },
  widthId: {
    minWidth: '120px',
    padding: '0 8px !important',
  },
  widthName: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthCreatedAt: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthCreatedBy: {
    minWidth: '220px',
    padding: '0 8px !important',
  },
  widthQuantityCus: {
    minWidth: '120px',
    padding: '0 8px !important',
  },
  widthActions: {
    minWidth: '140px',
    width: '6%',
    padding: '0 8px !important',
  },
  p0: {
    padding: '0',
    margin: '0',
  },
  heightHeader: {
    height: '52px',
  },
  f600: {
    fontWeight: 600,
  },
  checkBoxPadding: {
    padding: '10px 20px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  closeBtn: {
    color: '#ffffff',
    backgroundColor: '#dc3545',
    border: '2px solid #dc3545',
    padding: '6px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c82333',
      borderColor: '#bd2130',
    },
  },
  submitBtn: {
    color: 'white',
    backgroundColor: '#28a745',
    border: '2px solid #28a745',
    padding: '6px 26px',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#218838',
      borderColor: '#1e7e34',
    },
  },
  numCusInp: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 26px',
    margin: '12px 0',
    minHeight: '48px',
  },
  container: {
    flex: 1,
    width: '100%',
  },
  paginationBottom: {
    display: 'flex !important',
    justifyContent: 'end !important',
    alignItems: 'center !important',
  },
  buttonLoadMore: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: '#fff',
    border: '1px solid #dbdbdb',
    borderRadius: '.375em',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#363636',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: `BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    fontSize: '0.8rem',
    height: '1.8em',
    justifyContent: 'center',
    lineHeight: '1.5',
    padding: 'calc(.5em - 1px) 1em',
    position: 'relative',
    textAlign: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    touchAction: 'manipulation',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: '#b5b5b5',
    },
    '&:active': {
      borderColor: '#4a4a4a',
      outline: '0',
    },
    '&:focus': {
      borderColor: '#485fc7',
      outline: '0',
    },
    '&:focus:not(:active)': {
      boxShadow: 'rgba(72, 95, 199, 0.25) 0 0 0 0.125em',
    },
  },
  rowItem: {
    cursor: 'pointer',
  },

  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#637381',
    padding: '8px',
    marginRight: '8px',
    '&:hover': {
      color: '#212B36',
      backgroundColor: 'rgba(99, 115, 129, 0.08)',
      borderRadius: '50%',
    },
  },
  dialogTitle: {
    position: 'relative',
    paddingRight: '48px',
    margin: 0,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export const tagType = {
  BOMBOT_TAGS: 'BOMBOT_TAGS',
  FACEBOOK_TAGS: 'FACEBOOK_TAGS',
}

const TagView = ({
  onFanpageSelected,
  fanpageSelected,
  fanpageFull,
  getData,
  getFanpage,
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [tabVal, setTabVal] = useState(tagType.BOMBOT_TAGS)

  const [selectedPageId, setSelectedPageId] = useState(
    fanpageSelected.id || null
  )

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleChangeTab = (e, newVal) => {
    setTabVal(newVal)
  }

  const handleChangeFanpage = (key, value) => {
    switch (key) {
      case 'fanpage':
        if (value) {
          console.log('value ==>', value)
          onFanpageSelected(value)

          dispatch(getFanpageDetail(value.id))
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    const params = {
      // page: page,
      // size: rowsPerPage,
      // search: search ? search : undefined,
    }
    getFanpage(params)
  }, [])

  return (
    <MainLayout>
      <LoadingWrapper
        isOpen={isLoading}
        style={{
          padding: '0 15px 0 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: '3px',
          }}
        >
          {/* thanh chọn fanpage */}
          <Autocomplete
            freeSolo
            className=""
            style={{ flexGrow: 1 }}
            options={fanpageFull}
            onChange={(_, data) => {
              setSelectedPageId(data?.id || null)
              handleChangeFanpage('fanpage', data)
            }}
            getOptionLabel={(option) => option?.name || ''}
            // value={{ name: fanpageSelected && fanpageSelected.name }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                className="bg-white"
                label={t('customer_management.choose_page')}
                margin="normal"
                variant="outlined"
                style={{ width: '100%' }}
              />
            )}
          />
        </div>

        <Tabs
          value={tabVal}
          onChange={handleChangeTab}
          style={{
            paddingBottom: '12px',
          }}
        >
          <Tab value={tagType.BOMBOT_TAGS} label={'BomBot Tags'} />
          <Tab value={tagType.FACEBOOK_TAGS} label={'Facebook Tags'} />
        </Tabs>

        {tabVal === tagType.BOMBOT_TAGS && (
          <BombotTagsManagement
            getData={getData}
            getFanpage={getFanpage}
            tabVal={tabVal}
            setIsLoading={setIsLoading}
          />
        )}

        {tabVal === tagType.FACEBOOK_TAGS && (
          <FbTagsManagement
            getData={getData}
            getFanpage={getFanpage}
            tabVal={tabVal}
          />
        )}
      </LoadingWrapper>
    </MainLayout>
  )
}

export default TagView
