import axiosClient from './axiosClient'
import urlApi from './url'

const baseUrl = `${process.env.REACT_APP_API_URL}`

const scenarioApi = {
  get: (params) => {
    return axiosClient.get(
      `${baseUrl}api/v1.0/campaign/${params.id}/scenario`,
      { params }
    )
  },
}

export default scenarioApi
